import React from "react"

interface TableColumnIconProps{
size:number|string
}

export const TabelColumnIcon : React.FC<TableColumnIconProps>=(props)=>{
    const {size=15}=props
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_368_11916)">
        <path d="M0.5 2.9375C0.5 2.03994 1.22744 1.3125 2.125 1.3125H11.875C12.7713 1.3125 13.5 2.03994 13.5 2.9375V11.0625C13.5 11.9588 12.7713 12.6875 11.875 12.6875H2.125C1.22744 12.6875 0.5 11.9588 0.5 11.0625V2.9375ZM2.125 11.0625H6.1875V4.5625H2.125V11.0625ZM11.875 4.5625H7.8125V11.0625H11.875V4.5625Z" fill="#3E67F1"/>
        </g>
        <defs>
        <clipPath id="clip0_368_11916">
        <rect width="13" height="13" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    )
}

interface ExcelIconProps{
    size:number|string
}
    
export const ExcelIcon : React.FC<ExcelIconProps>=(props)=>{
        const {size=15}=props
        return (
            <svg width={size} height={size} viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_368_11920)">
            <path d="M6.1875 3.75V0.5H1.71875C1.04564 0.5 0.5 1.04564 0.5 1.71875V12.2812C0.5 12.9541 1.04564 13.5 1.71875 13.5H9.03125C9.70435 13.5 10.25 12.9544 10.25 12.2812V4.5625H7.02285C6.55059 4.5625 6.1875 4.19941 6.1875 3.75ZM7.40879 7.21328L6.1875 9.23437L7.43139 11.2557C7.59922 11.5271 7.40371 11.875 7.08633 11.875H6.41525C6.27416 11.875 6.14332 11.8018 6.06918 11.6816L5.375 10.5547L4.68158 11.6815C4.6082 11.8014 4.47617 11.875 4.33652 11.875H3.66367C3.34578 11.875 3.15103 11.5264 3.3176 11.2557L4.5625 9.23437L3.31836 7.21328C3.15078 6.9416 3.34629 6.59375 3.66367 6.59375H4.33475C4.47584 6.59375 4.60668 6.66695 4.68082 6.78705L5.375 7.91406L6.06842 6.78697C6.1418 6.66738 6.27383 6.59375 6.41348 6.59375H7.08455C7.40371 6.59375 7.59922 6.9416 7.40879 7.21328ZM7 0.5V3.75H10.25L7 0.5Z" fill="#3E67F1"/>
            </g>
            <defs>
            <clipPath id="clip0_368_11920">
            <rect width="9.75" height="13" fill="white" transform="translate(0.5 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            
        )
}

interface PdfIconProps{
        size:number|string
}
        
export const PdfIcon : React.FC<PdfIconProps>=(props)=>{
            const {size=15}=props
            return (
                <svg width={size} height={size} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_368_11921)">
                <path d="M4.92187 5.78125C4.92187 5.66938 4.83062 5.57812 4.71875 5.57812C4.60688 5.57812 4.51562 5.66953 4.51562 5.78125C4.51562 5.83361 4.52316 6.37311 4.74772 7.05713C4.79746 6.8375 4.92187 6.24336 4.92187 5.78125ZM6.75 0.5V3.75H10L6.75 0.5ZM2.28125 11.225C2.28125 11.4702 2.54709 11.5289 2.64637 11.3869C2.77053 11.2123 3.0125 10.8594 3.29941 10.3668C2.93836 10.5691 2.60752 10.8063 2.33406 11.0856C2.30029 11.1209 2.28125 11.1717 2.28125 11.225ZM5.9375 3.75V0.5H1.46875C0.795644 0.5 0.25 1.04564 0.25 1.71875V12.2812C0.25 12.9541 0.795644 13.5 1.46875 13.5H8.78125C9.45436 13.5 10 12.9544 10 12.2812V4.5625H6.77285C6.30059 4.5625 5.9375 4.19941 5.9375 3.75ZM7.66406 8.42187C8.28004 8.42187 8.78125 8.92309 8.78125 9.53906C8.78125 10.1654 8.30035 10.6562 7.68615 10.6562C7.53396 10.6562 7.38629 10.632 7.24893 10.585C6.73959 10.4134 6.27443 10.1798 5.86387 9.88958C5.25754 10.0193 4.70961 10.1821 4.231 10.3745C3.86461 11.0426 3.52945 11.5463 3.30754 11.8576C3.09629 12.1238 2.80937 12.2812 2.48285 12.2812C1.92375 12.2812 1.46875 11.8268 1.46875 11.2682C1.46875 10.9655 1.56371 10.7141 1.75262 10.5189C2.16344 10.0994 2.70146 9.73203 3.35273 9.42582C3.65107 8.84615 3.9098 8.25684 4.12283 7.67031C3.7082 6.65469 3.70312 5.79141 3.70312 5.78125C3.70312 5.22139 4.15889 4.76562 4.71875 4.76562C5.27887 4.76562 5.73437 5.22139 5.73437 5.78125C5.73437 6.39316 5.55088 7.19678 5.50743 7.37146C5.48189 7.45457 5.45576 7.53691 5.42918 7.61834C5.62799 7.98549 5.87859 8.30922 6.17668 8.58445C6.93027 8.45742 7.46855 8.42441 7.55234 8.42187H7.66406ZM4.22363 9.50352C4.68016 9.33848 5.141 9.21305 5.5673 9.11783C5.25195 8.81162 5.00439 8.48002 4.81066 8.14893C4.6248 8.64023 4.42422 9.09473 4.22363 9.50352ZM7.68691 9.84375C7.8418 9.84375 7.96875 9.7168 7.96875 9.56191C7.96875 9.37148 7.83164 9.23437 7.66406 9.23437H7.5625C7.55377 9.23517 7.13518 9.25897 6.52275 9.35498C6.80891 9.53271 7.13518 9.68988 7.50893 9.81506C7.5625 9.83359 7.62598 9.84375 7.68691 9.84375Z" fill="#3E67F1"/>
                </g>
                <defs>
                <clipPath id="clip0_368_11921">
                <rect width="9.75" height="13" fill="white" transform="translate(0.25 0.5)"/>
                </clipPath>
                </defs>
                </svg>  
            )
}

interface EditIconProps{
    size?:number|string;
    color?:string
}
export const EditIcon:React.FC<EditIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.00023H6.8C5.11984 4.00023 4.27976 4.00023 3.63803 4.32721C3.07354 4.61483 2.6146 5.07377 2.32698 5.63826C2 6.27999 2 7.12007 2 8.80023V17.2002C2 18.8804 2 19.7205 2.32698 20.3622C2.6146 20.9267 3.07354 21.3856 3.63803 21.6732C4.27976 22.0002 5.11984 22.0002 6.8 22.0002H15.2C16.8802 22.0002 17.7202 22.0002 18.362 21.6732C18.9265 21.3856 19.3854 20.9267 19.673 20.3622C20 19.7205 20 18.8804 20 17.2002V13.0002M7.99997 16.0002H9.67452C10.1637 16.0002 10.4083 16.0002 10.6385 15.945C10.8425 15.896 11.0376 15.8152 11.2166 15.7055C11.4184 15.5818 11.5914 15.4089 11.9373 15.063L21.5 5.50023C22.3284 4.6718 22.3284 3.32865 21.5 2.50023C20.6716 1.6718 19.3284 1.6718 18.5 2.50022L8.93723 12.063C8.59133 12.4089 8.41838 12.5818 8.29469 12.7837C8.18504 12.9626 8.10423 13.1577 8.05523 13.3618C7.99997 13.5919 7.99997 13.8365 7.99997 14.3257V16.0002Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}

interface DeleteIconProps{
    size?:number|string;
    color?:string
}
export const DeleteIcon:React.FC<DeleteIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 12V17" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 12V17" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 7H20" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    )
}

interface TagIconProps{
    size?:number|string;
    color?:string
}
export const TagIcon:React.FC<TagIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1229_41003)">
        <path d="M2.75 1.5H9.75781C10.5547 1.5 11.3141 1.81594 11.8766 2.37891L20.1266 10.6266C21.2984 11.7984 21.2984 13.7016 20.1266 14.8734L13.8734 21.1266C12.7016 22.2984 10.7984 22.2984 9.62656 21.1266L1.37891 12.8766C0.816078 12.3141 0.5 11.5547 0.5 10.7578V3.75C0.5 2.50734 1.50734 1.5 2.75 1.5ZM5.75 8.25C6.57969 8.25 7.25 7.57969 7.25 6.75C7.25 5.92031 6.57969 5.25 5.75 5.25C4.92172 5.25 4.25 5.92031 4.25 6.75C4.25 7.57969 4.92172 8.25 5.75 8.25Z" fill={color}/>
        </g>
        <defs>
        <clipPath id="clip0_1229_41003">
        <rect width={size} height={size} fill={color} transform="translate(0.5)"/>
        </clipPath>
        </defs>
        </svg>
        
    )
}

interface ActionIconProps{
    size?:number|string;
    color?:string
}
export const ActionIcon:React.FC<ActionIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.965 6.521C17.988 6.347 18 6.173 18 6C18 3.621 15.857 1.712 13.479 2.035C12.786 0.802 11.466 0 10 0C8.534 0 7.214 0.802 6.521 2.035C4.138 1.712 2 3.621 2 6C2 6.173 2.012 6.347 2.035 6.521C0.802 7.215 0 8.535 0 10C0 11.465 0.802 12.785 2.035 13.479C2.01193 13.6517 2.00024 13.8258 2 14C2 16.379 4.138 18.283 6.521 17.965C7.214 19.198 8.534 20 10 20C11.466 20 12.786 19.198 13.479 17.965C15.857 18.283 18 16.379 18 14C18 13.827 17.988 13.653 17.965 13.479C19.198 12.785 20 11.465 20 10C20 8.535 19.198 7.215 17.965 6.521ZM8.955 14.416L5.288 10.702L6.712 9.298L8.969 11.584L13.296 7.29L14.704 8.71L8.955 14.416Z" fill={color}/>
        </svg>
        
    )
}

interface ConditionIconProps{
    size?:number|string;
    color?:string
}
export const ConditionIcon:React.FC<ConditionIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1229_40973)">
        <path d="M14.875 1.25H11.125C10.4367 1.25 9.875 1.81172 9.875 2.5V6.25C9.875 6.93828 10.4367 7.5 11.125 7.5H14.875C15.5633 7.5 16.125 6.93828 16.125 6.25V2.5C16.125 1.81172 15.5625 1.25 14.875 1.25ZM8.625 3.4375H5.3125C4.94141 2.51953 4.05 1.875 3 1.875C1.61797 1.875 0.5 2.99297 0.5 4.375C0.5 5.75703 1.61797 6.875 3 6.875C4.05 6.875 4.94023 6.23047 5.31133 5.3125H8.42969C6.26562 6.58203 4.68359 8.72656 4.15234 11.25H6.08125C6.5207 9.62422 7.5168 8.23242 8.85469 7.28516C8.70703 6.96875 8.625 6.62109 8.625 6.25V3.4375ZM6.75 12.5H3C2.31172 12.5 1.75 13.0617 1.75 13.75V17.5C1.75 18.1883 2.31172 18.75 3 18.75H6.75C7.43828 18.75 8 18.1883 8 17.5V13.75C8 13.0625 7.4375 12.5 6.75 12.5ZM23 1.875C21.95 1.875 21.0598 2.51953 20.6887 3.4375H17.375V6.25C17.375 6.62109 17.2871 6.96797 17.1455 7.28516C18.4844 8.23438 19.4805 9.625 19.918 11.25H21.8469C21.3148 8.72578 19.7324 6.58203 17.5695 5.3125H20.6879C21.0586 6.23047 21.9492 6.875 23 6.875C24.382 6.875 25.5 5.75703 25.5 4.375C25.5 2.99297 24.3828 1.875 23 1.875ZM23 12.5H19.25C18.5617 12.5 18 13.0617 18 13.75V17.5C18 18.1883 18.5617 18.75 19.25 18.75H23C23.6883 18.75 24.25 18.1883 24.25 17.5V13.75C24.25 13.0625 23.6875 12.5 23 12.5Z" fill={color}/>
        </g>
        <defs>
        <clipPath id="clip0_1229_40973">
        <rect width={size} height={size} fill={color} transform="translate(0.5)"/>
        </clipPath>
        </defs>
        </svg>
        
    )
}

interface CloseIconProps{
    size?:number|string;
    color?:string
}
export const CloseIcon:React.FC<CloseIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.7055 8.29388C10.0961 8.68448 10.0961 9.31724 9.7055 9.70784C9.51176 9.90313 9.25553 10 8.9993 10C8.74307 10 8.48747 9.90235 8.29248 9.70705L4.99961 6.4159L1.70706 9.70627C1.51176 9.90313 1.25584 10 0.999922 10C0.744005 10 0.4884 9.90313 0.292946 9.70627C-0.0976487 9.31568 -0.0976487 8.68292 0.292946 8.29232L3.58644 4.99883L0.292946 1.7069C-0.0976487 1.3163 -0.0976487 0.68354 0.292946 0.292946C0.683541 -0.0976486 1.3163 -0.0976486 1.7069 0.292946L4.99961 3.588L8.29311 0.294508C8.6837 -0.0960862 9.31646 -0.0960862 9.70706 0.294508C10.0977 0.685103 10.0977 1.31787 9.70706 1.70846L6.41356 5.00195L9.7055 8.29388Z" fill={color}/>
        </svg>
        
    )
}

interface ClockIconProps{
    size?:number|string;
    color?:string
}
export const ClockIcon:React.FC<ClockIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1254_1501)">
        <path d="M10.875 5.625C10.875 5.00156 11.3766 4.5 12 4.5C12.6234 4.5 13.125 5.00156 13.125 5.625V11.4L17.1234 14.0625C17.6391 14.4094 17.7797 15.1078 17.3953 15.6234C17.0906 16.1391 16.3922 16.2797 15.8766 15.8953L11.3766 12.8953C11.0625 12.7266 10.875 12.375 10.875 11.9578V5.625ZM12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0ZM2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C17.3859 21.75 21.75 17.3859 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25C6.61406 2.25 2.25 6.61406 2.25 12Z" fill={color}/>
        </g>
        <defs>
        <clipPath id="clip0_1254_1501">
        <rect width={size} height={size} fill="white"/>
        </clipPath>
        </defs>
        </svg>       
    )
}

interface RepeatIconProps{
    size?:number|string;
    color?:string
}
export const RepeatIcon:React.FC<RepeatIconProps>=(props)=>{
    const {size=15,color='#000'}=props
    return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 11.9999C21.6717 11.9999 21 12.6707 21 13.4999C21 15.9815 18.9816 17.9999 16.5 17.9999H9V16.1249C9 15.681 8.73853 15.2783 8.33297 15.0965C7.92656 14.9202 7.45312 14.9905 7.12031 15.2905L3.37031 18.6655C3.13547 18.8765 3 19.1812 3 19.4999C3 19.8187 3.13552 20.1224 3.37209 20.3362L7.12209 23.7112C7.33125 23.9015 7.60313 23.9999 7.875 23.9999C8.03025 23.9999 8.18508 23.9677 8.33231 23.9032C8.7375 23.7233 9 23.3202 9 22.8749V20.9999H16.5C20.6353 20.9999 24 17.6352 24 13.4999C24 12.6702 23.3297 11.9999 22.5 11.9999ZM7.5 5.9999H14.9578L15 7.8749C15 8.31876 15.2615 8.72146 15.667 8.90333C15.8156 8.96708 15.9703 8.9999 16.0828 8.9999C16.3552 8.9999 16.6252 8.90029 16.8361 8.71133L20.5861 5.33633C20.8641 5.12333 21 4.81865 21 4.45771C21 4.09677 20.8645 3.83521 20.6279 3.62146L16.8779 0.246456C16.5476 -0.0494195 16.0721 -0.122685 15.6671 0.0545493C15.2625 0.278237 14.9578 0.680987 14.9578 1.12489L15 2.99989H7.5C3.36469 2.99989 0 6.36552 0 10.4999C0 11.3291 0.671719 11.9999 1.5 11.9999C2.32828 11.9999 3 11.3291 3 10.4999C3 8.02021 5.02031 5.9999 7.5 5.9999Z" fill={color}/>
        </svg>        
    )
}

interface RightArrowIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const RightArrowIcon:React.FC<RightArrowIconProps>=(props)=>{
    const {width=12,height=12,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2063 6.7063L9.20632 11.7063C9.01257 11.9032 8.75632 12 8.50007 12C8.24382 12 7.98819 11.9024 7.79319 11.7071C7.40257 11.3165 7.40257 10.6836 7.79319 10.293L11.0876 7.00005H1.50007C0.947884 7.00005 0.500122 6.55317 0.500122 6.00005C0.500122 5.44692 0.947884 5.00005 1.50007 5.00005H11.0876L7.79382 1.7063C7.40319 1.31567 7.40319 0.682861 7.79382 0.292236C8.18444 -0.0983886 8.81725 -0.0983886 9.20788 0.292236L14.2079 5.29223C14.5969 5.68442 14.5969 6.31567 14.2063 6.7063Z" fill={color}/>
        </svg>          
    )
}

interface DownArrowMarkIconProps{
    width?:number|string;
    height?:number|string;
    color?:string;
}
export const DownArrowMarkIcon:React.FC<DownArrowMarkIconProps>=(props)=>{
    const {width=15,height=10,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.34317 0.757324L0.928955 2.17154L8.00001 9.24263L15.0711 2.17157L13.6569 0.757353L8.00003 6.41419L2.34317 0.757324Z" fill={color}/>
        </svg>
               
    )
}

interface AddIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const AddIcon:React.FC<AddIconProps>=(props)=>{
    const {width=17,height=18,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.875 9C16.875 9.69102 16.3152 10.2504 15.625 10.2504H10V15.8754C10 16.5664 9.44023 17.125 8.75 17.125C8.05977 17.125 7.5 16.5664 7.5 15.8754V10.2504H1.875C1.18477 10.2504 0.625 9.69102 0.625 9C0.625 8.30898 1.18477 7.75039 1.875 7.75039H7.5V2.12539C7.5 1.43438 8.05977 0.875 8.75 0.875C9.44023 0.875 10 1.43438 10 2.12539V7.75039H15.625C16.3164 7.75 16.875 8.30859 16.875 9Z" fill={color}/>
        </svg>
            
    )
}

interface UpArrowIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const UpArrowIcon:React.FC<UpArrowIconProps>=(props)=>{
    const {width=9,height=6,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.12451 5.87499C7.96459 5.87499 7.80459 5.81396 7.68271 5.69189L4.37451 2.38476L1.06592 5.69335C0.821777 5.93749 0.426269 5.93749 0.182129 5.69335C-0.0620117 5.44921 -0.0620117 5.05371 0.182129 4.80957L3.93213 1.05957C4.17627 0.81543 4.57178 0.81543 4.81592 1.05957L8.56592 4.80957C8.81006 5.05371 8.81006 5.44921 8.56592 5.69335C8.44482 5.81445 8.28467 5.87499 8.12451 5.87499Z" fill={color}/>
        </svg>
        
            
    )
}

interface DownArrowIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const DownArrowIcon:React.FC<DownArrowIconProps>=(props)=>{
    const {width=9,height=6,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.12451 0.125006C7.96459 0.125006 7.80459 0.186041 7.68271 0.308111L4.37451 3.61524L1.06592 0.306646C0.821777 0.0625057 0.426269 0.0625057 0.182129 0.306646C-0.0620117 0.550786 -0.0620117 0.946293 0.182129 1.19043L3.93213 4.94043C4.17627 5.18457 4.57178 5.18457 4.81592 4.94043L8.56592 1.19043C8.81006 0.946293 8.81006 0.550786 8.56592 0.306646C8.44482 0.185552 8.28467 0.125006 8.12451 0.125006Z" fill={color}/>
        </svg>
        
        
            
    )
}

interface RightPointArrowIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const RightPointArrowIcon:React.FC<RightPointArrowIconProps>=(props)=>{
    const {width=8,height=14,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999843 14.0001C0.743968 14.0001 0.487968 13.9024 0.292969 13.7071C-0.0976562 13.3165 -0.0976562 12.6837 0.292969 12.2931L5.58734 7.00009L0.292969 1.70728C-0.0976562 1.31665 -0.0976562 0.683838 0.292969 0.293213C0.683593 -0.0974121 1.31641 -0.0974121 1.70703 0.293213L7.70703 6.29321C8.09765 6.68384 8.09765 7.31665 7.70703 7.70727L1.70703 13.7073C1.51234 13.9032 1.25609 14.0001 0.999843 14.0001Z" fill={color}/>
        </svg>        
    )
}

interface BranchIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const BranchIcon:React.FC<BranchIconProps>=(props)=>{
    const {width=13,height=10,color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.1875 0.625H5.3125C4.96836 0.625 4.6875 0.905859 4.6875 1.25V3.125C4.6875 3.46914 4.96836 3.75 5.3125 3.75H7.1875C7.53164 3.75 7.8125 3.46914 7.8125 3.125V1.25C7.8125 0.905859 7.53125 0.625 7.1875 0.625ZM4.0625 1.71875H2.40625C2.2207 1.25977 1.775 0.9375 1.25 0.9375C0.558984 0.9375 0 1.49648 0 2.1875C0 2.87852 0.558984 3.4375 1.25 3.4375C1.775 3.4375 2.22012 3.11523 2.40566 2.65625H3.96484C2.88281 3.29102 2.0918 4.36328 1.82617 5.625H2.79062C3.01035 4.81211 3.5084 4.11621 4.17734 3.64258C4.10352 3.48437 4.0625 3.31055 4.0625 3.125V1.71875ZM3.125 6.25H1.25C0.905859 6.25 0.625 6.53086 0.625 6.875V8.75C0.625 9.09414 0.905859 9.375 1.25 9.375H3.125C3.46914 9.375 3.75 9.09414 3.75 8.75V6.875C3.75 6.53125 3.46875 6.25 3.125 6.25ZM11.25 0.9375C10.725 0.9375 10.2799 1.25977 10.0943 1.71875H8.4375V3.125C8.4375 3.31055 8.39355 3.48398 8.32275 3.64258C8.99219 4.11719 9.49023 4.8125 9.70898 5.625H10.6734C10.4074 4.36289 9.61621 3.29102 8.53477 2.65625H10.0939C10.2793 3.11523 10.7246 3.4375 11.25 3.4375C11.941 3.4375 12.5 2.87852 12.5 2.1875C12.5 1.49648 11.9414 0.9375 11.25 0.9375ZM11.25 6.25H9.375C9.03086 6.25 8.75 6.53086 8.75 6.875V8.75C8.75 9.09414 9.03086 9.375 9.375 9.375H11.25C11.5941 9.375 11.875 9.09414 11.875 8.75V6.875C11.875 6.53125 11.5937 6.25 11.25 6.25Z" fill={color}/>
        </svg>
      
    )
}

interface TickIconProps{
    width?:number|string;
    height?:number|string;
    color?:string
}
export const TickIcon:React.FC<TickIconProps>=(props)=>{
    const {width='10',height='8',color='#000'}=props
    return(
        <svg width={width} height={height} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.375 1.5C9.375 1.65992 9.31396 1.81992 9.19189 1.9418L4.19189 6.9418C4.07031 7.06445 3.91016 7.125 3.75 7.125C3.58984 7.125 3.43008 7.06396 3.3082 6.94189L0.808203 4.44189C0.686133 4.32031 0.625 4.16016 0.625 4C0.625 3.64297 0.916992 3.375 1.25 3.375C1.40992 3.375 1.56992 3.43604 1.6918 3.55811L3.75 5.61719L8.30859 1.05859C8.42969 0.936133 8.58984 0.875 8.75 0.875C9.08398 0.875 9.375 1.14258 9.375 1.5Z" fill={color}/>
        </svg>
             
    )
}

