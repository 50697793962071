import styled from "styled-components";

export const SelectParent=styled.div`
width:100%;
position:relative;
.react-flow-select-parent-container{
    border:1px solid #D0D5DD;
    width:100%;
    height:45px;
    border-radius:5px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 20px;
    cursor:pointer;
    user-select:none;
    position:relative;
    background:#FFFFFF;
}

.react-flow-select-option-container{
    border:1px solid #D0D5DD;
    min-height:100px;
    max-height:220px;
    margin-top:5px;
    border-radius:5px;
    position:absolute;
    width:100%;
    z-index:10;
    background:#FFFFFF;
    overflow:auto;
}

.react-flow-select-option{
    padding:10px 20px;
    font-size:16px;
    font-weight:400;
    cursor:pointer;
    letter-spacing:0.4px;
}

.react-flow-select-option:hover{
    background:#F2F4F7;
}

.react-flow-select-text{
    font-size:16px;
    font-weight:400;
}
`