import styled from "styled-components";

export const BasicInfoParent=styled.div`
width:100%;

.basic-info-title{
    color:#98A2B3;
    font-size:18px;
    font-weight:500;
    letter-spacing:0.4px;
}

.basic-info-form-parent-container{
    width:100%;
    margin-top:20px;
     display:flex;
    flex-direction:column;
    gap:20px;
}

.basic-info-form-inner-container{
    display:flex;
    flex-direction:column;
    gap:5px;
}

.basic-info-form-input-field{
    border:1px solid #D0D5DD;
    background:#FFFFFF;
    outline:none;
    height:45px;
    border-radius:5px;
    padding:0 10px;
}

.basic-info-form-input-field:focus{
    border:1px solid #2955F0;
}

.basic-info-form-label{
    font-size:14px;
    font-weight:500;
    color:#101828;
}

.field-is-required::after{
     content: " *";
    color: red; 
    margin-left: 4px; 
}
`