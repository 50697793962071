import styled from "styled-components";

export const ButtonNodeParent=styled.div`
.button-node-button-container{
    border:none;
    font-size:20px;
    font-weight:600;
    color:#FFFFFF;
    background:#000000;
    padding:1px 10px;
    border-radius:100%;
    cursor:pointer;
}

.confirm-popover-parent-container{
    height:auto;
    border-radius:20px;
    margin-left:50px;
    position:absolute;
    top:-7px;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:20px;
}

.popover-text-tag{
    font-size:16px;
    font-weight:500;
    letter-spacing:0.3px;
    padding:10px 40px;
    cursor:pointer;
    background:#2E59E7;
    border-radius:30px;
    color:#FFFFFF;
}

`