import styled from "styled-components";

export const ExecuteOnNodeParent=styled.div`
position:relative;
.execute-on-node-parent-container{
    width:240px;
    height:150px;
    border:4px solid #9900E2;
    border-radius:30px;
    background:#FFFFFF;
}

.execute-on-node-title-container{
    display:flex;
    font-size:22px;
    font-weight:500;
    letter-spacing:0.3px;
    color:#9900E2;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding:15px 20px;
    cursor:pointer;
    user-select:none;
}

.execute-on-node-body-conteiner{
    width:100%;
    display:flex;
    flex-direction:column;
    gap:10px;
}

.execute-on-node-body-inner-container{
    width:100%;
    display:flex;
    padding:0 20px;
}

.execute-on-node-body-left-text{
    font-size:16px;
    font-weight:700;
    letter-spacing:0.3px;
    color:#98A2B3;
    width:50px;
}
.execute-on-node-body-right-text{
   font-size:16px;
    font-weight:400;
    letter-spacing:0.3px;
    color:#101828;
}

.execute-on-node-tag-icon-container{
    padding:5px;
    width:fit-content;
    border-radius:100%;
    background:#E7CFFF;
    position:absolute;
    margin-top:-10px;
    margin-left:25px;
}
`