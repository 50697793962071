import React,{useEffect, useState, useRef} from 'react'
import {ButtonNodeParent} from '../../style/ButtonNodeStyles'
import { Handle, Position } from '@xyflow/react';

interface ButtonNodeProps{
    data:any;
    isConnectable?:any;
}

const ButtonNode:React.FC<ButtonNodeProps> = (props) => {
    const{isConnectable,data}=props

    const {openAndCloseModal,setNodeDetails,parentId,id,position,setActiveTab}=data
    const [openPopover,setOpenPopover]=useState<{}|any>({})

    const buttonNodeRef=useRef<HTMLDivElement>(null)

    const openAndClosePopover=()=>{
      setOpenPopover({[id]:openPopover?.[id]?false:true})
      setNodeDetails({parentId:parentId,id:id,position:position})
    }

    const AddNode=()=>{
     openAndClosePopover()
    }

    const switchTab=(tab:any)=>{
       setActiveTab(tab)
       setOpenPopover({})
       openAndCloseModal()
    }

    useEffect(() => {
      // Function to close the filter when clicked outside
      function handleClickOutside(event: MouseEvent): void {
        if (buttonNodeRef.current && !buttonNodeRef.current.contains(event.target as Node)) {
         setOpenPopover({})
        }
      }
  
      // Add event listener when the filter is open
      if (openPopover) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      // Clean up event listener when component unmounts or filter closes
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [openPopover]);

    console.log('qqq-225',data)
  return (
   <ButtonNodeParent ref={buttonNodeRef}>
     <Handle
     ref={buttonNodeRef}
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div role='button' onClick={()=>AddNode()} className='button-node-button-container'>
           +
        </div>
        {openPopover?.[id]&& <div className='confirm-popover-parent-container'>
          <p role='button' onClick={()=>{switchTab('condition')}} className='popover-text-tag'>Condition</p>
          <p role='button' onClick={()=>{switchTab('action')}} className='popover-text-tag'>Action</p>
         </div>}
        <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
   </ButtonNodeParent>
  )
}

export default ButtonNode