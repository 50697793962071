import React from 'react';
import Chart from './Charts';
import GridTable from './GridTable';
import ReactSequenceFlow from './ReactFlowOld/index';
import ReactSequenceComp from './ReactFlow/index'
// import FromWidget from './Form/index'

interface IndexProps {
  pageId?: string;
  payload: any;
  data:any
}

const ComponentsRenderer: React.FC<IndexProps> = (props) => {
  console.log(props,'props')
  const { data } = props;
  // const { data, component='chart' } = payload;

  let components: Record<string, React.ComponentType<any>> = {
    chart: Chart,
    table:GridTable,
    reactFlow:ReactSequenceFlow,
    reactFlowNew:ReactSequenceComp
    // form:FromWidget,

    // Add more components as needed
  };

  let Comp: React.ComponentType<any> | undefined = components[props.payload.type]||components?.reactFlowNew;

  if (!Comp) {
    console.error(`Component '${props.payload}' not found.`);
    return null; // Or render a fallback component or handle the error as needed
  }
console.log('qqq-9',data)
  return <Comp {...data} payload={props.payload}/>;
};

export default ComponentsRenderer;
