import { renderReactComponent } from "../utills/CommonLogic";
  
  interface ComponentInjectorListener {
    init: (id: any, payload: any,data:any) => void;
  }
  
  class ComponentInjector {
    public listeners: ComponentInjectorListener;
  
    constructor() {
      this.listeners = {
        init: (id, payload,data) => this.init(id, payload,data),
      };
    }
  
    init(id: any, payload: any,data:any): void {
      this.renderDashboard(id, payload,data);
    }
  
    renderDashboard(id: any, payload: any,data:any): void {

      renderReactComponent(id, payload,data);
    }
  }
  
  (window as any).refCustomElem = new ComponentInjector();
  