import React,{useState,useMemo, useEffect, useRef} from 'react'
import {MultiSelectParent} from './styles/MultiSelectStyles'

interface MultiSelectProps{
    options?:any;
    value?:any;
    key?:any;
    onChange?:(arg:any)=>void;
    name?:any
}

const MultiSelect:React.FC<MultiSelectProps>= (props) => {
    const{options,value,key,onChange,name}=props


    const actionMultiSelectRef=useRef<HTMLDivElement>(null)

    const [selectedOption,setSelectedOption]=useState<any|[]>(value&&value?.length>0?[...value]:[])
    const [search,setSearch]=useState<string>('')
    const [isOptionOpen,setIsOptionOpen]=useState(false)

    useEffect(()=>{
        setSelectedOption(value&&value?.length>0?[...value]:[])
    },[key])

    const openOption=()=>{
        if(!isOptionOpen)
        setIsOptionOpen(true)
    }

    const closeOption=()=>{
        if(isOptionOpen)
        setIsOptionOpen(false)
    }

    const captureSelectvalue=(options:any)=>{
        setSearch('')
        if(!selectedOption?.includes(options)){
            setSelectedOption([...selectedOption,options])
        }else{
            setSelectedOption((opt:any)=>opt&&opt?.filter((value:any)=>value!==options))
        }
    }

    const filterOptions = useMemo(() => {
        // First filter the options based on the search string
        let filtered = options?.filter((value: any) => {
            // Check if the search string is empty, if so, return all options
            if (search === '') {
                return true;
            }
            // Check if the option label includes the search string
            return value?.label?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase());
        }) || [];
    
        // Check if the search string doesn't match any of the filtered options
        const isSearchPresent = filtered.some((value: any) =>
            value?.label?.toString()?.toLowerCase() === search?.toString()?.toLowerCase()
        );
    
        // If search string doesn't match any option, add it as a new item
        if (!isSearchPresent && search !== '') {
            filtered = [...filtered, { label: search, value: search }];
        }
    
        return filtered;
    }, [options, search]);
    

    const handleSearch=(e:any)=>{
        setSearch(e?.target?.value)
    }

    useEffect(() => {
        // Function to close the filter when clicked outside
        function handleClickOutside(event: MouseEvent): void {
          if (actionMultiSelectRef.current && !actionMultiSelectRef.current.contains(event.target as Node)) {
           closeOption()
          }
        }
    
        // Add event listener when the filter is open
        if (isOptionOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        }
    
        // Clean up event listener when component unmounts or filter closes
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isOptionOpen]);

      useEffect(()=>{
        let e={
            target:{
                name:name,
                value:selectedOption
            }
        }
        if(onChange&&selectedOption?.length>0){
            onChange(e)
        }
      },[selectedOption])

  return (
   <MultiSelectParent ref={actionMultiSelectRef}>
        <div role='button' className='multi-select-parent-container' onClick={openOption} >
            {selectedOption&&selectedOption?.length>0&&selectedOption?.map((tag:any,index:number)=>{
                return(
                    <div className='tag-parent-container' key={index}>
                        <p>{tag}</p>
                        <p role='button' onClick={()=>captureSelectvalue(tag)} className='tag-close-button'>x</p>
                    </div>
                )
            })}
            <input className='multi-select-search-input'  value={search} onChange={(e:any)=>handleSearch(e)}/>
            <p className='open-more-button'>{'{ }'}</p>
        </div>
        {isOptionOpen&&<div className='multi-select-option-container'>
            {filterOptions&&filterOptions?.map((value:any,key:number)=>{
                return(
                    <p onClick={()=>captureSelectvalue(value?.value)} key={key} className={`multi-select-option-txt ${selectedOption?.includes(value?.value)?'selected-multi-option':''}` }>{value?.label}</p>
                )
            })}
        </div>}
   </MultiSelectParent>
  )
}

export default MultiSelect