import React,{ useCallback, useState} from 'react'
import {ConditionParent} from '../../../style/ConditionStyles'
import Select from '../../Select'
import {AddIcon,UpArrowIcon,DownArrowIcon} from '../../../../../utills/Svg'
import {generateExpression} from '../../../../../utills/CommonLogic'

interface ConditionProps{
apiData?:any
values?:any
setValues?:any
openAndCloseModal?:()=>void
addNewNode?:(arg:any)=>void
isEditEnabled?:any
enableEditModeForNodes?:any
updateNode?:any
}

const Condition:React.FC<ConditionProps> = (props) => {
    const {apiData,values,setValues,openAndCloseModal,addNewNode,isEditEnabled,updateNode}=props
    const {attributes}=apiData
    const [conditionFields,setConditionFields]=useState<any>(values&&values?.condition?values?.condition:[{id:1,attribute:'',condition:'',value:'',connector:'',columnType:''}])
    const [count,setCount]=useState(values&&values?.condition?.at(values?.condition?.length-1)?.id?values?.condition?.at(values?.condition?.length-1)?.id:1)

    

    const addConditionFields=()=>{
        let arr=[...conditionFields]

        let newArr=arr&&arr.map((field:any,index:number)=>{
            let connector=field?.connector
            if(index<count&&connector?.length<=0){
                return {...field,connector:'AND'}
            }else{
                return field
            }

        })
        setConditionFields([...newArr,{
            [`id`]:count+1,
             [`attribute`]:'',
             [`condition`]:'',
             [`value`]:'',
             [`connector`]:'',
            }
        ])

        setCount(count+1)
    }

    const switchConditionGate=(index:number)=>{

        let value=conditionFields.at(index)

        let connector:any=value?.[`connector`]=='AND'?'OR':'AND'

        let arr=[...conditionFields]

        let newArr=arr&&arr.map((field:any,key:number)=>{
            if(key==index){
                return {...field,[`connector`]:connector}
            }else{
                return field
            }
        })

        setConditionFields([...newArr])

    }

    const deleteConditionFields=(index:number)=>{
        let arr=[...conditionFields]

        if(conditionFields.length>1){
        let newArr=arr&&arr.filter((field:any,key:number)=>{
            if(key!==index){
                return field
            }
        })
        if(newArr?.length<=1){
            newArr=[{...newArr[0],connector:''}]
        }
        setConditionFields([...newArr])
    }
    }

    const attributesOption=attributes&&attributes?.map((value:any)=>{
        return {
            label:value?.attribute_name,
            value:value?.uuid,
            data:value,
        }
    })

    function debounce(func:any, delay:any) {
        let timer:any;
        return (...args:any) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    const handleChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const { data } = e;
      
        setConditionFields((prevFields: any[]) => {
          const updatedField = {
            ...prevFields[index],
            attribute: name === 'attribute' ? value : prevFields[index]?.attribute,
            condition: name === 'condition' ? value : (name === 'attribute' ? '' : prevFields[index]?.condition),
            value: name === 'value' ? value : prevFields[index]?.value,
            columnType: data?.data?.reference_column?.datatype || prevFields[index]?.columnType,
          };
      
          const updatedFields = [...prevFields];
          updatedFields[index] = updatedField;
      
          return updatedFields;
        });
      };

      const handleDebouncedChange = useCallback(
        debounce((value:any,index:number) => {
          handleChange(value,index);
        }, 500), // Adjust debounce time as needed
        [] // Empty dependency array means the function is created once
      );
    
      

  const conditionOptionsBasedOnType:any={
    'text':[
        {label:'Equal',value:'=',},
        { label:'Like',value:'like'},
        {label:'Includes',value:'Includes'}
    ],
    'number':[
        {label:'Equal',value:'=',},
        { label:'Like',value:'like'},
        {label:'Less Than',value:'<'},
        {label:'Greater Than',value:'>'},
        {label:'Less Than OR Equal',value:'<='},
        {label:'Greater Than OR Equal',value:'>='},
        {label:'Not Equal',value:'!=='}
    ]
  }

  const cancel=(arg:boolean)=>{
    if(openAndCloseModal){
        openAndCloseModal()
        if(arg){
        setValues([])
        }
    }
  }

  const saveCondition=()=>{
    setValues({...values,condition:[...conditionFields]})
    setTimeout(() => {
        cancel(false)
        if(addNewNode){
                addNewNode('Condition')
        }
    }, 100);
  }

  const updateCondition=()=>{
    setValues({condition:[...conditionFields]})
    setTimeout(() => {
        cancel(false)
        updateNode()
    }, 100);
  }

  return (
    <ConditionParent>
        <div className='condition-parent-container'>
            {conditionFields&&conditionFields.map((field:any,index:number)=>{
                return(
                    <div className='condition-inner-parent-container'>
                    <div className='condition-serial-no-container' role='button' onClick={()=>field?.connector?.length>0?deleteConditionFields(index):null}>
                        <p className='condition-serial-no'>{index+1}</p>
                        {field?.connector?.length>0&&<p className='close-condition-icon'>x</p>}
                    </div>
                    <Select key={index} returnData={true} name={`attribute`} options={attributesOption} value={field?.attribute} onChange={(e:any)=>handleChange(e,index)}/>
                    <Select key={index} disabled={field?.attribute.length<=0} value={field?.condition} name={`condition`} options={conditionOptionsBasedOnType?.[field.columnType]} onChange={(e:any)=>handleChange(e,index)}/>
                    <input key={field?.value} defaultValue={field?.value} disabled={field?.condition.length<=0} name={`value`} className='condition-input-field' placeholder='Value' onChange={(e:any)=>handleDebouncedChange(e,index)}/>
                    <div className='condition-gate-parent'>
                        {field?.connector?.length>0&&<div role='button' onClick={()=>switchConditionGate(index)} className='condition-gate-parent-container'>
                            <UpArrowIcon color='#98A2B3'/>
                            <p className='condition-gate-text'>{field?.connector}</p>
                            <DownArrowIcon color='#98A2B3'/>
                        </div>}
                        {field?.connector?.length<=0&&<p role='button' onClick={addConditionFields}>
                            <AddIcon color='#2E59E7'/>
                         </p>}
                    </div>
                </div>
                )
            })}
        </div>
        <div className='condition-formula-parent-container'>
            <p className='condition-formula-title'>Formula</p>
            <p className='condition-formula-text'>{generateExpression(conditionFields)}</p>
        </div>
        <div className='condition-button-container'>
            <button onClick={()=>cancel(true)} className='condition-cancel-button'>Cancel</button>
            {!isEditEnabled&&<button onClick={saveCondition} className='condition-save-button'>Save</button>}
            {isEditEnabled&&<button onClick={updateCondition} className='condition-save-button'>Update</button>}
        </div>
    </ConditionParent>
  )
}

export default Condition