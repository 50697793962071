import styled from "styled-components";

export const ConditionNodeParent=styled.div`
display:flex;
position:relative;

.condition-node-overall-parent-container{
    position:relative;
}
.condition-node-parent-container{
    width:220px;
    height:140px;
    border:4px solid #0948C3;
    border-radius:30px;
    background:#FFFFFF;
}

.condition-node-title-container{
    display:flex;
    font-size:22px;
    font-weight:500;
    letter-spacing:0.3px;
    color:#0948C3;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding:15px 20px;
    cursor:pointer;
    user-select:none;
}

.condition-node-body-conteiner{
    width:100%;
    display:flex;
    align-items:center;
    height:50px;
}

.condition-node-body-inner-container{
    width:100%;
    display:flex;
}

.condition-node-body-right-text{
    padding:0 20px;
    font-size:16px;
    font-weight:400;
    letter-spacing:0.3px;
    color:#101828;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
}

.condition-node-tag-icon-container{
    padding:5px;
    width:fit-content;
    border-radius:100%;
    background:#DEE1FF;
    position:absolute;
    margin-top:-10px;
    margin-left:25px;
}

.action-buttons-parent-container{
position:absolute;
left:260px;
top:15px;
display:flex;
flex-direction:row;
gap:20px;
}

.action-delete-button{
padding:8px 30px;
border:1px solid red;
border-radius:20px;
background:red;
color:#FFFFFF;
font-size:16px;
font-weight:700;
letter-spacing:0.5px
}

.action-edit-button{
padding:8px 30px;
border:1px solid #0948C3;
border-radius:20px;
background:#0948C3;
color:#FFFFFF;
font-size:16px;
font-weight:700;
letter-spacing:0.5px
}
`