import styled from "styled-components";

export const ExecuteOnParent=styled.div`
width:100%;

.execute-on-title{
    color:#98A2B3;
    font-size:18px;
    font-weight:500;
    letter-spacing:0.4px;
}

.execute-on-sequence-name{
    font-size:18px;
    font-weight:500;
    letter-spacing:0.4px;
    color:#3E67F1;
    margin:0 10px !important;
    padding:0 !important;
}

.execute-on-tab-parent-container{
    margin-top:40px;
    width:400px;
    height:40px;
    border-radius:10px;
    background:#F6F6F6;
    display:flex;
    align-items:center;
}

.execute-on-tab{
    height:100%;
    padding:8px 20px 0 20px;
    width:50%;
    text-align:center;
    border-radius:11px;
    color:#101828;
    font-size:15px;
    font-weight:500;
    letter-spacing:0.2px;
}

.execute-on-tab-active{
    border:2px solid #ABBEFF;
    backgroung:#FFFFFF;
    color:#3E67F1;
    box-shadow: 0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A;
}

.execute-on-time-tab-parent-container{
    margin-top:30px;
    width:100%;
    display:flex;
    flex-direction:column;
    gap:20px;
}

.execute-on-time-and-date-parent{
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:10px;
}

.execute-on-date-field, .execute-on-time-field{
    border:1px solid #D0D5DD;
    height:45px;
    padding:0 20px;
    width:250px;
    border-radius:5px;
  background: url('calendar-icon.png') no-repeat right;
  background-size: 20px;
  padding-right: 30px;

}

.execute-on-run-button-container{
display:flex;
margin-top:20px;
align-items:center;
flex-direction:row;
gap:5px;
}

.execute-on-run-label{
    color:#343741;
    font-size:16px;
    font-weight:400;
    letter-spacing:0.3px;
    cursor:pointer;
    user-select:none; 
    margin:6px 0 0 0  !important;  
}

.execute-on-run-checkbox{
    height:15px;
    width:15px;
    cursor:pointer;
    user-select:none; 
}

`