import React from 'react'
import {ReactWireModalsParent} from '../../../style/ReactWireModalsStyles'
import Condition from './Condition';
import Action from './Action';

interface ReactWireModalsProps{
    apiData?:any;
    values?:any;
    setValues?:any;
    openAndCloseModal?:()=>void;
    addNewNode?:(arg:any)=>void;
    activeTab?:string;
    isEditEnabled?:any;
    enableEditModeForNodes?:any;
    updateNode?:any;
}

const ReactWireModals:React.FC<ReactWireModalsProps> = (props) => {
    const {activeTab,apiData,values,setValues,openAndCloseModal,addNewNode,enableEditModeForNodes,isEditEnabled,updateNode}=props

  return (
   <ReactWireModalsParent>
        <p className='react-wire-modal-title'>{activeTab?.toString()?.toUpperCase()}</p>
        {activeTab=='condition'&&<Condition updateNode={updateNode} enableEditModeForNodes={enableEditModeForNodes} isEditEnabled={isEditEnabled} addNewNode={addNewNode} apiData={apiData} values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
        {activeTab=='action'&&<Action updateNode={updateNode} enableEditModeForNodes={enableEditModeForNodes} isEditEnabled={isEditEnabled} addNewNode={addNewNode} values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
   </ReactWireModalsParent>
  )
}

export default ReactWireModals