import styled from "styled-components";

export const ReactWireModalsParent=styled.div`
    border:1px solid #CCCCCC;
    width:65vw;
    background:#FFFFFF;
    border-radius:5px;
    padding:20px 30px 20px 30px;
    overflow:auto;

.react-wire-modals-tab-parent-container{
    display:flex;
    align-items:center;
    flex-direction:row;
    gap:30px;
    margin-bottom:10px;
}


.react-wire-modal-title{
    font-size:18px;
    font-weight:500;
    letter-spacing:0.5px;
    color:#2E59E7;
    margin-bottom:20px;
    width:100%;
    text-align:center;
}

.react-wire-modals-tab-text{
    font-size:20px;
    font-weight:500;
    letter-spacing:0.5px;
    width:100%;
    text-align:center;
    cursor:pointer;
}

.react-wire-modals-in-active-tab{
    color:#667085;
}

.react-wire-modals-active-tab{
    color:#2E59E7;
}
`