import styled from "styled-components";

export const ReactFlowCompParent=styled.div`
width:100%;

.react-flow-first-parent-container{
 border:1px solid #E4E7EC;
 height:80px;
 width:100%;
 border-bottom:none;
 display:flex;
 align-items:center;
 justify-content:space-between;
 padding:0 40px;
}

.react-flow-first-parent-title{
font-size:18px;
font-weight:500;
letter-spacing:0.3px;
color:#98A2B3;
}

.react-flow-first-parent-button-container{
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:10px;
}

.react-flow-first-parent-back-button{
  color:#2E59E7;
  font-size:16px;
  font-weight:500;
   padding:10px 30px;
}

.react-flow-first-parent-next-button{
 background-color:#2E59E7;
  color:#FFFFFF;
  font-size:14px;
  font-weight:600;
  padding:5px 40px;
  border-radius:5px;
  letter-spacing:0.3px;
}

.react-flow-second-parent-container{
    border:1px solid #E4E7EC;
    width:100%;
    display:flex;
}

.react-flow-left-container{
    border-right:1px solid #E4E7EC;
    width:300px;
    padding:20px 40px;
}

.react-flow-right-container{
    padding:20px;
    min-height:80vh;
    width:calc(100% - 300px)
}

`