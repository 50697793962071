import styled from "styled-components";

export const MultiSelectParent=styled.div`
position:relative;

.multi-select-parent-container{
    border:1px solid #CCCCCC;
    min-:40px;
    border-radius:6px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    gap:5px;
    align-items:center;
    padding:5px;
}

.multi-select-option-container{
    border:1px solid #CCCCCC;
    margin-top:3px;
    max-height:180px;
    border-radius:6px;
    overflow:auto;
    position:absolute;
    width:100%;
    background:#FFFFFF;
    z-index:99999999999999999;
}

.multi-select-option-txt{
    font-size:15px;
    fonr-weight:400;
    letter-spacing:0.5px;
    padding:5px 10px;
    cursor:pointer;
}

.multi-select-option-txt:hover{
    background:#ABBEFF3f;
}

.multi-select-option-txt::first-child{
 border-radius:6px 0 6px 0;
}

.multi-select-option-txt::last-child{
 border-radius:0 6px 0 6px;
}

.multi-select-search-input{
    height:70%;
    flex:1;
    outline:none;
}

.open-more-button{
    padding:3px 0;
    width:35px;
   display:flex;
   align-items:center;
   justify-content:center;
   border-radius:6px;
   background:#E7EDFF;
   color:#2E59E7;
   cursor:pointer
}

.tag-parent-container{
    border:1px solid #E4E7EC;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:10px;
    padding:3px 10px;
    border-radius:6px;
    font-size:16px;
    font-weight:400;
    user-select:none;
    background:#F2F4F7;
}

.tag-close-button{
    cursor:pointer;
    color:#667085;
    font-size:18px;
    font-weight:400;
}

.selected-multi-option{
    background:#ABBEFF;
}
`