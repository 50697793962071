import React from 'react'
import {ExecuteOnNodeParent} from '../../style/ExecuteOnNodeStyle'
import {RightPointArrowIcon,TagIcon} from '../../../../utills/Svg'
import { Handle, Position } from '@xyflow/react';

interface ExecuteOnNodeProps{
    data:any;
    isConnectable?:any;
}

const ExecuteOnNode:React.FC<ExecuteOnNodeProps> = (props) => {
    const{isConnectable,data}=props

    const{switchSteps}=data
    const {executeon}=data?.values


    const startTime=executeon?.time?.start?.time

    const frequency=executeon?.time?.frequency

    const goToExecuteOnStep=()=>{
      switchSteps('executeOn')
    }

    console.log('qqq-332',data)
  return (
    <ExecuteOnNodeParent>
         <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
        <div className='execute-on-node-tag-icon-container'>
            <TagIcon size={17} color='#9900E2'/>
        </div> 
        <div className='execute-on-node-parent-container'>
            <div role='button' onClick={goToExecuteOnStep} className='execute-on-node-title-container'>
                <p>Execute</p>
                <RightPointArrowIcon width={10} height={30} color='#9900E2'/>
            </div>
            <div  className='execute-on-node-body-conteiner'>
                <div className='execute-on-node-body-inner-container'>
                    <p className='execute-on-node-body-left-text'>Run</p>
                    <p className='execute-on-node-body-right-text'>{frequency}</p>
                </div>
                <div className='execute-on-node-body-inner-container'>
                    <p className='execute-on-node-body-left-text'>Start</p>
                    <p className='execute-on-node-body-right-text'>{startTime}</p>
                </div>

            </div>
        </div>
        <Handle
        type="source"
        position={Position.Right}
        id="r"
        isConnectable={isConnectable}
      />
    </ExecuteOnNodeParent>
  )
}

export default ExecuteOnNode