export const initialNodes=`[
    {
      "id": "1",
      "draggable": true,
      "position": {
        "x": 0,
        "y": 0
      },
      "data": {
        "label": "Sequence Name"
      },
      "style": {
        "background": "#101828",
        "color": "#fff",
        "padding": "5px 10px",
        "fontSize": "16px",
        "borderRadius": "50px"
      }
    },
    {
      "id": "end",
      "draggable": true,
      "position": {
        "x": 360,
        "y": 543
      },
      "data": {
        "label": "End Automation"
      },
      "style": {
        "background": "#101828",
        "color": "#fff",
        "padding": "5px 10px",
        "fontSize": "16px",
        "borderRadius": "50px"
      }
    }
  ]`

export const initialEdges=`[
    {
      "id": "e1-end",
      "source": "1",
      "target": "end"
    }
  ]`