import React,{useEffect, useState,useRef} from 'react'
import {ReactFlowCompParent} from './style/ReactFlowCompStyle'
import Steps from './ts/Steps'
import BasicInfo from './ts/BasicInfo'
import ExecuteOn from './ts/ExecuteOn'
import ReactWire from './ts/ReactWire'
import {initialNodes,initialEdges} from '../../data/SequenceData'

interface ReactFlowCompProps{
  closeComp?:()=>void
  saveSequence?:(payload:any)=>void
  title?:string|number
  apiData?:any
}

const ReactFlowComp:React.FC<ReactFlowCompProps> = (props) => {
  const{closeComp,saveSequence,title,apiData}=props

  const childRef = useRef<any>(null);

  console.log('React-seq-comp',props)

  const options=[
    {label:'Basic Info',value:'basicInfo'},
    {label:'Execute On',value:'executeOn'},
    {label:'Sequence View',value:'sequenceView'}
] 

  const [activeStep,setActiveStep]=useState('sequenceView')
  const [activeTab,setActiveTab]=useState('time')
  const [isModalOpen,setIsModalOpen]=useState(false)
  const [values,setValues]=useState({sequence:{nodes:JSON.parse(initialNodes),edges:JSON.parse(initialEdges)}})
  const [apiResData,setApiResData]=useState({})
  const [tempNodeValues,setTempNodevalues]=useState({})

  const switchSteps=(value:any)=>{
    if(value!==activeStep){
      setActiveStep(value)
    }
  }

  const switchTab=(value:any)=>{
    if(value!==activeTab){
      setActiveTab(value)
    }
  }

  const openAndCloseModal=()=>{
    setIsModalOpen(!isModalOpen)
  }

  const triggerChildFunc=()=>{
    if(childRef.current){
    childRef.current.addNewNode('ExecuteOn')
    }
  }

  const activeTabIndex=options&&options?.findIndex((ele:any)=>ele?.value==activeStep)

  const goToNextStep=()=>{
    setActiveStep(options?.[activeTabIndex+1]?.value)
    if(activeTabIndex==options?.length-2){
      setTimeout(() => {
        triggerChildFunc()
      }, 100);
    }
  }

  const save=(payload:any)=>{
    if(saveSequence){
     saveSequence(payload)
     console.log('payload',payload)
    }
  }

  useEffect(()=>{
    apiData?.attributes.then((res:any)=>setApiResData({...apiResData,attributes:res}))
  },[apiData])

  useEffect(()=>{
    let sequence={
      nodes:JSON.parse(initialNodes),
      edges:JSON.parse(initialEdges)
    }

    setValues({...values,sequence:{...sequence}})

  },[initialNodes,initialEdges])
  
  return (
    <ReactFlowCompParent>
     <div className='react-flow-first-parent-container'>
        <p className='react-flow-first-parent-title'>{title}</p>
        <div className='react-flow-first-parent-button-container'>
          <button className='react-flow-first-parent-back-button' onClick={()=>{if(closeComp){closeComp()}}}>Cancel</button>
          {(options?.length-1!==activeTabIndex)&&<button className='react-flow-first-parent-next-button' onClick={()=>goToNextStep()}>Next</button>}
          {(options?.length-1==activeTabIndex)&&<button className='react-flow-first-parent-next-button' onClick={()=>save(values)}>Save</button>}
        </div>
     </div>
      <div className='react-flow-second-parent-container'>
        <div className='react-flow-left-container'>
          <Steps options={options} activeStep={activeStep} switchSteps={switchSteps}/>
        </div>
        <div className='react-flow-right-container'>
          {activeStep=='basicInfo'&&<BasicInfo values={values} setValues={setValues}/>}
          {activeStep=='executeOn'&&<ExecuteOn onClick={switchTab} activeTab={activeTab} modalOpen={isModalOpen} values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
          {activeStep=='sequenceView'&&<ReactWire switchSteps={switchSteps} apiData={apiResData} values={values} setValues={setValues} ref={childRef} tempValues={tempNodeValues} setTempValues={setTempNodevalues}/>}
        </div>
      </div>
    </ReactFlowCompParent>
  )
}

export default React.memo(ReactFlowComp)