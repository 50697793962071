import React, { useState } from 'react'
import {DailyParent} from '../../style/DailyStyle'

interface DailyProps{
    values?:any
    setValues?:any
    openAndCloseModal:()=>void
}

const Daily:React.FC<DailyProps> = (props) => {
    const {values,setValues,openAndCloseModal}=props

    const [day,setDay]=useState<any>({...values?.executeOn?.time?.days})

    const startDate=values?.executeOn?.time?.start?.date
  
    const handleChange=(e:any)=>{
      const {name,value}=e?.target
      setDay({[name]:value})
    }

    const save=()=>{
        setValues({...values,executeOn:{...values?.executeOn,['time']:{...values?.executeOn?.time,days:day}}})
        setTimeout(() => {
          openAndCloseModal()
        }, 100);
      }

  return (
    <DailyParent>
       <p className='daily-card-title'>Set Recurrence</p>
        <div className='daily-card-Repeat-Every-container'>
            <p className='daily-card-Repeat-Every-txt'>Repeat Every</p>
            <input type='number' className='daily-card-Repeat-Every-input-field' defaultValue={day?.repeatEvery} name='repeatEvery' onChange={(e:any)=>handleChange(e)}/>
            <p className='daily-card-Repeat-Every-txt'>{day?.repeatEvery>1?"Day's":"Day"}</p>
        </div>
        <p className='daily-card-Repeat-Every-final-txt'>Occurs every {day?.repeatEvery>1?day?.repeatEvery+" day's":"day"} starting {startDate?startDate:'YYYY/MM/DD'}</p>
        <div className='recurrence-button-container'>
            <button className='recurrence-cancel-button' onClick={()=>openAndCloseModal()}>Cancel</button>
            <button className='recurrence-save-button' onClick={()=>save()}>Save</button>
        </div>
    </DailyParent>
  )
}

export default Daily