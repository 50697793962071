import React,{useState} from 'react'
import {HourlyParent} from '../../style/HourlyStyle'

interface HourlyProps{
  values?:any
  setValues?:any
  openAndCloseModal:()=>void
}

const Hourly:React.FC<HourlyProps> = (props) => {
  const{values,setValues,openAndCloseModal}=props

  const [hour,setHour]=useState({...values?.executeOn?.time?.hours})

  const startDate=values?.executeOn?.time?.start?.date

  const handleChange=(e:any)=>{
    const {name,value}=e?.target
    setHour({[name]:value})
  }

  const save=()=>{
    setValues({...values,executeOn:{...values?.executeOn,['time']:{...values?.executeOn?.time,hours:hour}}})
    setTimeout(() => {
      openAndCloseModal()
    }, 100);
  }
  
  return (
    <HourlyParent>
        <p className='hourly-card-title'>Set Recurrence</p>
        <div className='hourly-card-Repeat-Every-container'>
            <p className='hourly-card-Repeat-Every-txt'>Repeat Every</p>
            <input type='number' className='hourly-card-Repeat-Every-input-field' defaultValue={hour?.repeatEvery} name='repeatEvery' onChange={(e:any)=>handleChange(e)}/>
            <p className='hourly-card-Repeat-Every-txt'>{hour?.repeatEvery>1?"Hour's":'Hour'}</p>
        </div>
        <p className='hourly-card-Repeat-Every-final-txt'>Occurs every {hour?.repeatEvery>1? hour?.repeatEvery+" hour's":"hour"} starting {startDate?startDate:'YYYY/MM/DD'}</p>
        <div className='recurrence-button-container'>
            <button className='recurrence-cancel-button' onClick={()=>openAndCloseModal()}>Cancel</button>
            <button className='recurrence-save-button' onClick={()=>save()}>Save</button>
        </div>
    </HourlyParent>
  )
}

export default Hourly