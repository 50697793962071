import React,{useEffect, useRef, useState} from 'react'
import {RepeatIcon,RightPointArrowIcon,TickIcon} from '../../../../utills/Svg'
import {ActionNodeParent} from '../../style/ActionNodeStyles'
import { Handle, Position } from '@xyflow/react';

interface ActionNodeProps{
    data?:any;
    isConnectable?:any;
}

const ActionNode:React.FC<ActionNodeProps> = (props) => {
    const {data={varient:'No'},isConnectable}=props
    const{varient='yes',setActiveTab, setNodeDetails,id,enableEditModeForNodes,openAndCloseModal,deleteNode}=data

    const [openButton,setOpenButton]=useState<{}|any>({})

    const actionNodeButtonRef=useRef<HTMLDivElement>(null)

    const openEditModal=()=>{
      setActiveTab('action')
      setNodeDetails({id:id,type:'action'})
      enableEditModeForNodes()
     setTimeout(() => {
      openAndCloseModal()
     }, 100);
    }

    const openAndCloseButton=()=>{
      setOpenButton({[id]:openButton?.[id]?false:true})
    }

    useEffect(() => {
      // Function to close the filter when clicked outside
      function handleClickOutside(event: MouseEvent): void {
        if (actionNodeButtonRef.current && !actionNodeButtonRef.current.contains(event.target as Node)) {
         setOpenButton({})
        }
      }
  
      // Add event listener when the filter is open
      if (openButton) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      // Clean up event listener when component unmounts or filter closes
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [openButton]);

    const deleteNodes=()=>{
      deleteNode(id)
    }

  return (
    <ActionNodeParent  ref={actionNodeButtonRef}>
         <Handle
         ref={actionNodeButtonRef}
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className='action-node-overall-parent-container'>
        <div className={`action-node-tag-icon-container ${varient?.toLowerCase()=='yes'?'action-node-yes':'action-node-no'}`}>
            {varient?.toLowerCase()=='no'&&<RepeatIcon size={12} color='#B54708'/>}
            {varient?.toLowerCase()=='yes'&&<TickIcon width={10} height={8} color='#008315'/>}
        </div> 
        <div className={`action-node-parent-container ${varient?.toLowerCase()=='yes'?'action-node-parent-yes':'action-node-parent-no'}`}>
            <div role='button' onClick={openAndCloseButton} className={`action-node-title-container ${varient?.toLowerCase()=='yes'?'action-node-title-container-yes':'action-node-title-container-no'}`}>
                <p>Action</p>
                <RightPointArrowIcon width={10} height={30} color={varient?.toLowerCase()=='yes'?'#008315':'#B54708'}/>
            </div>
            <div  className='action-node-body-conteiner'>
                <div className='action-node-body-inner-container'>
                    <p className='action-node-body-left-text'>Affected</p>
                    <p className='action-node-body-right-text'>27</p>
                </div>

            </div>
        </div>
      </div>
        {openButton?.[id]&&<div className='action-buttons-parent-container'>
          <button className='action-edit-button' onClick={openEditModal} >Edit</button>
          <button className='action-delete-button' onClick={deleteNodes}>Delete</button>
      </div>}
        <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </ActionNodeParent>
  )
}

export default ActionNode