import React, { useState } from 'react'
import {YearlyParent} from '../../style/YearlyStyle'
import Select from '../Select'

interface YearlyProps{
    values?:any
    setValues?:any
    openAndCloseModal:()=>void
}

const Yearly:React.FC<YearlyProps> = (props) => {
    const {values,setValues,openAndCloseModal}=props

    const [year,setYear]=useState<any>({...values?.executeOn?.time?.years})
    const [selectedTab,setSelectedTab]=useState(values?.executeOn?.time?.years?.onThe?'onThe':'onDate')

    const weekOptions=[
        {label:'1',value:'1'},
        {label:'2',value:'2'},
        {label:'3',value:'3'},
        {label:'4',value:'4'},
        {label:'5',value:'5'},
    ]

    const dayOptions=[
        {label:'Sunday',value:'Sunday'},
        {label:'Monday',value:'Monday'},
        {label:'Tuesday',value:'Tuesday'},
        {label:'Wednesday',value:'Wednesday'},
        {label:'Thursday',value:'Thursday'},
        {label:'Friday',value:'Friday'},
        {label:'Saturday',value:'Saturday'},
    ]

    const yearOptions=[
        {label:'January',value:'January'},
        {label:'February',value:'February'},
        {label:'March',value:'March'},
        {label:'April',value:'April'},
        {label:'May',value:'May'},
        {label:'June',value:'June'},
        {label:'July',value:'July'},
        {label:'August',value:'August'},
        {label:'September',value:'September'},
        {label:'October',value:'October'},
        {label:'November',value:'November'},
        {label:'December',value:'December'},
    ]
  
    const handleChange=(e:any)=>{
      const {name,value}=e?.target
      setYear({...year,[name]:value})
    }

    const captureOnDate=(e:any)=>{
        const {name,value}=e?.target
      setYear({...year,onDate:{...year?.onDate,[name]:value}})
    }

    const captureSelectedTab = (e: any) => {
        const value = e.target.value;
        setSelectedTab(value);
      
        if (value === 'onDate') {
          // When switching to 'onDay', remove 'onThe' from the state
          const { onThe, ...updatedMonth } = year;
          setYear(updatedMonth);
        } else if (value === 'onThe') {
          // When switching to 'onThe', remove 'onDay' from the state
          const { onDate, ...updatedMonth } = year;
          setYear(updatedMonth);
        }
      };
      

    const captureSelect=(e:any)=>{
        const{name,value}=e?.target
        setYear({...year,onThe:{...year?.onThe,[name]:value}})
    }

    const save=()=>{
        setValues({...values,executeOn:{...values?.executeOn,['time']:{...values?.executeOn?.time,years:year}}})
        setTimeout(() => {
          openAndCloseModal()
        }, 100);
      }

  return (
    <YearlyParent>
       <p className='yearly-card-title'>Set Recurrence</p>
        <div className='yearly-card-Repeat-Every-container'>
            <p className='yearly-card-Repeat-Every-txt'>Repeat Every</p>
            <input type='number' className='yearly-card-Repeat-Every-input-field' defaultValue={year?.repeatEvery} name='repeatEvery' onChange={(e:any)=>handleChange(e)}/>
            <p className='yearly-card-Repeat-Every-txt'>{year?.repeatEvery>1?"Year's":"Year"}</p>
        </div>
        <div className='on-day-and-the-parent-container'>
            <div className='on-day-and-the-inner-parent-container'>
                <div className='on-day-and-the-radio-container'>
                    <input onChange={(e:any)=>captureSelectedTab(e)} defaultChecked={selectedTab=='onDate'?true:false} id="onDate" name="month" className='on-day-and-the-radio' type='radio' value='onDate'/>
                    <label htmlFor="onDate" className='on-day-and-the-radio-label'>On Date</label>
                </div>
                <div style={{width:"156px"}}>
                    <Select index={selectedTab} options={yearOptions} disabled={selectedTab!=='onDate'} onChange={(e:any)=>captureOnDate(e)} name='month' value={year?.onDate?.month}/>
                </div>
                <input disabled={selectedTab!=='onDate'} type='number' className='yearly-card-Repeat-Every-input-field' key={selectedTab} defaultValue={year?.onDate?.date} name='date' onChange={(e:any)=>captureOnDate(e)}/>
            </div>
            <div className='on-day-and-the-inner-parent-container'>
                <div className='on-day-and-the-radio-container'>
                    <input onChange={(e:any)=>captureSelectedTab(e)} defaultChecked={selectedTab=='onThe'?true:false} name="month" id="onThe" className='on-day-and-the-radio' type='radio' value='onThe'/>
                    <label htmlFor='onThe' className='on-day-and-the-radio-label'>On The</label>
                </div>
               <Select index={selectedTab} disabled={selectedTab!=='onThe'} onChange={(e:any)=>captureSelect(e)} options={weekOptions} name='occurence' value={year?.onThe?.occurence}/>
                <Select index={selectedTab} disabled={selectedTab!=='onThe'} onChange={(e:any)=>captureSelect(e)} options={dayOptions} name='day' value={year?.onThe?.day}/>
                <p className='of-text'>of</p>
                <Select index={selectedTab} options={yearOptions} disabled={selectedTab!=='onThe'} onChange={(e:any)=>captureSelect(e)} name='month' value={year?.onThe?.month}/>
            </div> 
        </div>
        {selectedTab=='onDate'&&<p className='yearly-card-Repeat-Every-final-txt'>Occurs every {year?.repeatEvery>1?year?.repeatEvery+" year's":"year"} on day {year?.onDate?.date||'DD'} of {year?.onDate?.month||'MM'}</p>}
        {selectedTab=='onThe'&&<p className='yearly-card-Repeat-Every-final-txt'>Occurs every {year?.repeatEvery>1?year?.repeatEvery+" year's":"year"} on the {year?.onThe?.occurence||'WW'} {year?.onThe?.day||'DD'} of {year?.onThe?.month||'MM'}</p>}
        <div className='recurrence-button-container'>
            <button className='recurrence-cancel-button' onClick={()=>openAndCloseModal()}>Cancel</button>
            <button className='recurrence-save-button' onClick={()=>save()}>Save</button>
        </div>
    </YearlyParent>
  )
}

export default Yearly