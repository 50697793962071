import React from 'react'
import {RecurrenceModalsParent} from '../style/RecurrenceModalsStyle'
import {Modal} from '@decisionm/react-gen-lib'
import Hourly from './ModalForms/Hourly'
import Daily from './ModalForms/Daily'
import Weekly from './ModalForms/Weekly'
import Monthly from './ModalForms/Monthly'
import Yearly from './ModalForms/Yearly'

interface RecurrenceModalsProps{
  values?:any
  setValues?:any
  openAndCloseModal:()=>void
}

const RecurrenceModals:React.FC<RecurrenceModalsProps> = (props) => {
  const{values,setValues,openAndCloseModal}=props

  const activeForm=values?.executeOn?.time?.frequency
  return (
    <RecurrenceModalsParent>
        {activeForm!=='Does not repeat'&&<Modal>
            {activeForm=='Hourly'&&<Hourly values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
            {activeForm=='Daily'&&<Daily values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
            {activeForm=='Weekly'&&<Weekly values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
            {activeForm=='Monthly'&&<Monthly values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
            {activeForm=='Yearly'&&<Yearly values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
        </Modal>}
    </RecurrenceModalsParent>
  )
}

export default RecurrenceModals