import React, { useState } from 'react'
import {MonthlyParent} from '../../style/Monthly'
import Select from '../Select'

interface MonthlyProps{
    values?:any
    setValues?:any
    openAndCloseModal:()=>void
}

const Monthly:React.FC<MonthlyProps> = (props) => {
    const {values,setValues,openAndCloseModal}=props

    const [month,setMonth]=useState<any>({...values?.executeOn?.time?.months})
    const [selectedTab,setSelectedTab]=useState(values?.executeOn?.time?.months?.onThe?'onThe':'onDay')

    const startDate=values?.executeOn?.time?.start?.date

    const weekOptions=[
        {label:'1',value:'1'},
        {label:'2',value:'2'},
        {label:'3',value:'3'},
        {label:'4',value:'4'},
        {label:'5',value:'5'},
    ]

    const dayOptions=[
        {label:'Sunday',value:'Sunday'},
        {label:'Monday',value:'Monday'},
        {label:'Tuesday',value:'Tuesday'},
        {label:'Wednesday',value:'Wednesday'},
        {label:'Thursday',value:'Thursday'},
        {label:'Friday',value:'Friday'},
        {label:'Saturday',value:'Saturday'},
    ]
  
    const handleChange=(e:any)=>{
      const {name,value}=e?.target
      setMonth({...month,[name]:value})
    }

    const captureSelectedTab = (e: any) => {
        const value = e.target.value;
        setSelectedTab(value);
      
        if (value === 'onDay') {
          // When switching to 'onDay', remove 'onThe' from the state
          const { onThe, ...updatedMonth } = month;
          setMonth(updatedMonth);
        } else if (value === 'onThe') {
          // When switching to 'onThe', remove 'onDay' from the state
          const { onDay, ...updatedMonth } = month;
          setMonth(updatedMonth);
        }
      };
      

    const captureSelect=(e:any)=>{
        const{name,value}=e?.target
        setMonth({...month,onThe:{...month?.onThe,[name]:value}})
    }

    const save=()=>{
        setValues({...values,executeOn:{...values?.executeOn,['time']:{...values?.executeOn?.time,months:month}}})
        setTimeout(() => {
          openAndCloseModal()
        }, 100);
      }

  return (
    <MonthlyParent>
         <p className='monthly-card-title'>Set Recurrence</p>
        <div className='monthly-card-Repeat-Every-container'>
            <p className='monthly-card-Repeat-Every-txt'>Repeat Every</p>
            <input type='number' className='monthly-card-Repeat-Every-input-field' defaultValue={month?.repeatEvery} name='repeatEvery' onChange={(e:any)=>handleChange(e)}/>
            <p className='monthly-card-Repeat-Every-txt'>{month?.repeatEvery>1?"Month's":"Month"}</p>
        </div>
        <div className='on-day-and-the-parent-container'>
            <div className='on-day-and-the-inner-parent-container'>
                <div className='on-day-and-the-radio-container'>
                    <input onChange={(e:any)=>captureSelectedTab(e)} defaultChecked={selectedTab=='onDay'?true:false} id="onDay" name="month" className='on-day-and-the-radio' type='radio' value='onDay'/>
                    <label htmlFor="onDay" className='on-day-and-the-radio-label'>On Day</label>
                </div>
                <input disabled={selectedTab!=='onDay'} type='number' className='monthly-card-Repeat-Every-input-field' key={selectedTab} defaultValue={month?.onDay} name='onDay' onChange={(e:any)=>handleChange(e)}/>
            </div>
            <div className='on-day-and-the-inner-parent-container'>
                <div className='on-day-and-the-radio-container'>
                    <input onChange={(e:any)=>captureSelectedTab(e)} defaultChecked={selectedTab=='onThe'?true:false} name="month" id="onThe" className='on-day-and-the-radio' type='radio' value='onThe'/>
                    <label htmlFor='onThe' className='on-day-and-the-radio-label'>On The</label>
                </div>
               <Select index={selectedTab} disabled={selectedTab!=='onThe'} onChange={(e:any)=>captureSelect(e)} options={weekOptions} name='occurence' value={month?.onThe?.occurence}/>
                <Select index={selectedTab} disabled={selectedTab!=='onThe'} onChange={(e:any)=>captureSelect(e)} options={dayOptions} name='day' value={month?.onThe?.day}/>
            </div>
        </div>
        {selectedTab=='onThe'&&<p className='monthly-card-Repeat-Every-final-txt'>Occurs every {month?.repeatEvery>1?month?.repeatEvery+" month's" : "month"} on {month?.onThe?.occurence||'WW'} {month?.onThe?.day||'DD'} starting {startDate?startDate:'YYYY/MM/DD'}</p>}
        {selectedTab=='onDay'&&<p className='monthly-card-Repeat-Every-final-txt'>Occurs every {month?.repeatEvery>1?month?.repeatEvery+" month's" : "month"} on day {month?.onDay} starting {startDate?startDate:'YYYY/MM/DD'}</p>}
        <div className='recurrence-button-container'>
            <button className='recurrence-cancel-button' onClick={()=>openAndCloseModal()}>Cancel</button>
            <button className='recurrence-save-button' onClick={()=>save()}>Save</button>
        </div>
    </MonthlyParent>
  )
}

export default Monthly