import React from "react";
import * as ReactDOMClient from 'react-dom/client';
import ComponentsRenderer from "../components";
export const renderReactComponent = (id: any, payload: any, data: any): void => {
    console.log('Angular Props',typeof(id), payload, data)
    const container = document.getElementById(id);
    if (!container) {
        console.error(`Element with ID ${id} not found.`);
        return;
    }
    const root = ReactDOMClient.createRoot(container as HTMLElement);

    root.render(
        <React.Fragment >
            <ComponentsRenderer pageId={id} payload={payload} data={data}/>
        </React.Fragment>
    )
}

export const generateExpression=(data:any)=>{
    if(data?.length>1){
        let expression = `${1}`;
  
        // Loop through the array and build the expression
        for (let i = 0; i < data.length - 1; i++) {
          expression = `(${expression} ${data[i].connector} ${i+2})`;
        }
        
        return expression;
    }else{
        return "If 1 is true"
    }
  }