import React, { useState } from 'react'
import {WeeklyParent} from '../../style/Weekly'

interface WeeklyProps{
    values?:any
    setValues?:any
    openAndCloseModal:()=>void
}

const Weekly:React.FC<WeeklyProps> = (props) => {
    const {values,setValues,openAndCloseModal}=props

    const daysOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const [week,setWeek]=useState<any>({...values?.executeOn?.time?.weeks,days:values?.executeOn?.time?.weeks?.days?[...values?.executeOn?.time?.weeks?.days]:[...daysOrder]})

    // const startDate=values?.executeOn?.startDate
    const days=[
        {label:'S',value:'Sunday'},
        {label:'M',value:'Monday'},
        {label:'T',value:'Tuesday'},
        {label:'W',value:'Wednesday'},
        {label:'T',value:'Thursday'},
        {label:'F',value:'Friday'},
        {label:'S',value:'Saturday'},
    ]
  
    const handleChange=(e:any)=>{
      const {name,value}=e?.target
      setWeek({...week,[name]:value})
    }

    const handleDaysChange=(day:string)=>{
        let arr=[...week?.days]

        if(arr?.includes(day)){
           setWeek({...week,days: arr.filter((value:any)=>{
                    if(value!==day){
                        return value
                     }
                })
            })
        }else{
            setWeek({...week,days:[...week?.days,day].sort((a:any,b:any)=>daysOrder.indexOf(a) - daysOrder.indexOf(b))})
        }
    }

    const save=()=>{
        setValues({...values,executeOn:{...values?.executeOn,['time']:{...values?.executeOn?.time,weeks:week}}})
        setTimeout(() => {
          openAndCloseModal()
        }, 100);
      }

  return (
    <WeeklyParent>
        <p className='weekly-card-title'>Set Recurrence</p>
        <div className='weekly-card-Repeat-Every-container'>
            <p className='weekly-card-Repeat-Every-txt'>Repeat Every</p>
            <input type='number' className='weekly-card-Repeat-Every-input-field' defaultValue={week?.repeatEvery} name='repeatEvery' onChange={(e:any)=>handleChange(e)}/>
            <p className='weekly-card-Repeat-Every-txt'>{week?.repeatEvery>1?"Week's":"Week"}</p>
        </div>
        <div className='weekly-card-days-parent-container'>
            {days?.map((day:any,index:number)=><p role='button' onClick={()=> handleDaysChange(day.value)} className={`${week?.days?.includes(day?.value)?'active-weekly-card-days-text':'weekly-card-days-text'}`} key={index}>{day?.label}</p>)}
        </div>
        <p className='weekly-card-Repeat-Every-final-txt'>Occurs every {week?.repeatEvery>1?week?.repeatEvery+" week's":''} {week?.days?.[0]} through {week?.days?.[week?.days?.length-1]}</p>
        <div className='recurrence-button-container'>
            <button className='recurrence-cancel-button' onClick={()=>openAndCloseModal()}>Cancel</button>
            <button className='recurrence-save-button' onClick={()=>save()}>Save</button>
        </div>
    </WeeklyParent>
  )
}

export default Weekly