import styled from "styled-components";

export const ActionNodeParent=styled.div`

.action-node-overall-parent-container{
    position:relative;
}

.action-node-parent-container{
    width:200px;
    height:120px;
    border-radius:30px;
    background:#FFFFFF;
}

.action-node-parent-yes{
    border:4px solid #008315;
}

.action-node-parent-no{
    border:4px solid #B54708;
}

.action-node-title-container{
    display:flex;
    font-size:22px;
    font-weight:500;
    letter-spacing:0.3px;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding:15px 20px;
    cursor:pointer;
    user-select:none;
}

.action-node-title-container-yes{
    color:#008315;
}

.action-node-title-container-no{
    color:#B54708;
}

.action-node-body-conteiner{
    width:100%;
    display:flex;
    flex-direction:column;
    gap:10px;
}

.action-node-body-inner-container{
    width:100%;
    display:flex;
    padding:0 20px;
}

.action-node-body-left-text{
    font-size:16px;
    font-weight:700;
    letter-spacing:0.3px;
    color:#98A2B3;
    width:80px;
}
.action-node-body-right-text{
   font-size:16px;
    font-weight:400;
    letter-spacing:0.3px;
    color:#101828;
}

.action-node-tag-icon-container{
    padding:5px;
    width:fit-content;
    border-radius:100%;
    position:absolute;
    margin-left:25px;
}

.action-node-no{
 background:#F5E5CE;
  margin-top:-9px;
}

.action-node-yes{
 background:#DAF2E4;
  margin-top:-7px;
}

.action-buttons-parent-container{
position:absolute;
left:220px;
top:15px;
display:flex;
flex-direction:row;
gap:20px;
}

.action-delete-button{
padding:8px 30px;
border:1px solid red;
border-radius:20px;
background:red;
color:#FFFFFF;
font-size:16px;
font-weight:700;
letter-spacing:0.5px
}

.action-edit-button{
padding:8px 30px;
border:1px solid #0948C3;
border-radius:20px;
background:#0948C3;
color:#FFFFFF;
font-size:16px;
font-weight:700;
letter-spacing:0.5px
}
`