import React, { useEffect, useRef, useCallback, useState,useImperativeHandle,forwardRef} from 'react';
import ExecuteOnNode from './CustomNodes/ExecuteOnNode';
import ButtonNode from './CustomNodes/ButtonNode';
import {Modal} from '@decisionm/react-gen-lib'
import ReactWireModals from './ModalForms/ReactWire';
import ConditionNode from './CustomNodes/ConditionNode';
import ActionNode from './CustomNodes/ActionNode';
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowInstance,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

interface ReactWireProps {
  values?: any;
  ref?:any;
  apiData?:any;
  setValues?:any;
  tempValues?:any;
  setTempValues?:any;
  switchSteps?:(arg:any)=>void;
}

const ReactWire:React.FC<ReactWireProps>=forwardRef((props,ref) => {
  const { values,apiData,tempValues,setTempValues,setValues,switchSteps} = props;
  
  
  const initialNodes= [...values?.sequence?.nodes];

  const initialEdges = [...values?.sequence?.edges];

  const nodeTypes = {
    ExecuteOn: ExecuteOnNode,
    Button:ButtonNode,
    Condition:ConditionNode,
    Action:ActionNode
  };

  let sequenceName = values?.basicInfo?.sequenceName || 'Sequence Name';

  const [nodes, setNodes] = useNodesState<any | []>(initialNodes);
  const [edges, setEdges] = useEdgesState<any | []>(initialEdges);
  const reactWire = useRef<ReactFlowInstance | null>(null);

  const [id, setId] = useState(1);
  const [isModalOpen,setIsModalOpen]=useState(false)
  const [nodeDetails,setNodeDetails]=useState<any>(null)
  const [activeTab,setActiveTab]=useState('condition')
  const [isEditEnabled,setIsEditEnabled]=useState<boolean>(false)

  const tempValuesRef = useRef(tempValues);

  useEffect(() => {
    tempValuesRef.current = tempValues;
  }, [tempValues]);

  const openAndCloseModal=()=>{
    setIsModalOpen(!isModalOpen)
  }

  const enableEditModeForNodes=()=>{
    setIsEditEnabled(!isEditEnabled)
  }

  useEffect(() => {
    setNodes((nds: any) => {
      return nds?.map((value: any, key: number) => {
        if (key === 0) {
          return { ...value, data: { ...value?.data, label: sequenceName } };
        } else {
          return { ...value };
        }
      });
    });
  }, [sequenceName]);

  const addNewNode = (nodeTypeArg?:any) => {
    let nodeId = `node-${id + 1}`; // Ensure ID is a string
    const activeButtonVarient=nodeDetails?.id?.toString()?.charAt(nodeDetails?.id?.length-1);
    let newNode = {
      id: nodeId,
      draggable: true,
      position: id==1?{ x: -45, y: 135 }:id==2?{...nodeDetails?.position}:{...nodeDetails?.position,x:activeButtonVarient=='a'?nodeDetails?.position?.x-300:nodeDetails?.position?.x},
      tabType: nodeTypeArg,
      type: nodeTypeArg,
      data: {
        values: {
          [nodeTypeArg?.toLowerCase()]: nodeTypeArg == 'Condition'
            ? [...tempValuesRef.current?.condition]  // Use tempValuesRef.current instead of tempValues
            : nodeTypeArg == 'Action'
              ? { ...tempValuesRef.current?.action }
              : { ...values?.executeOn }
        },
        parentId: id == 1 ? id : `node-${id}`,
        id: nodeId,
        varient: activeButtonVarient == 'a' ? 'yes' : 'no',
        setTempValues:"(arg)=>setTempValues(arg)",
        openAndCloseModal:"()=>openAndCloseModal()",
        setNodeDetails:"(arg)=>setNodeDetails(arg)",
        enableEditModeForNodes:"()=>enableEditModeForNodes()",
        switchSteps:"(arg)=>switchSteps(arg)",
        setActiveTab:"(arg)=>setActiveTab(arg)",
        deleteNode:"(arg)=>deleteNode(arg)"
      },
      style: {
        border: '0',
        padding: '0',
        width: 'fit-content',
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
        zIndex: '10'
      }
    };

    const newButton={
        id: 'button'+nodeId+'a',
        draggable: true,
        position: id==1?{ x: 418, y: 316 }:id==2?{x:312,y:673}:{x:activeButtonVarient=='a'?nodeDetails?.position?.x-(300):nodeDetails?.position?.x,y:nodeDetails?.position?.y+300},
        tabType: 'Button',
        type: 'Button',
        data: { 
          ...values,
          position: id==1?{ x: 418, y: 316 }:id==2?{x:312,y:673}:{x:activeButtonVarient=='a'?nodeDetails?.position?.x-(300):nodeDetails?.position?.x,y:nodeDetails?.position?.y+300},
          id: 'button'+nodeId+'a',
          parentId:`node-${id+1}`,
          openAndCloseModal:"()=>openAndCloseModal()",
          setNodeDetails:"(arg)=>setNodeDetails(arg)",
          setActiveTab:"(arg)=>setActiveTab(arg)"
        },
        style: {
          border: '0',
          padding: '0',
          width: 'fit-content',
          outline: 'none',
          boxShadow: 'none',
          background: 'transparent',
          zIndex: '10'
        }
    }

    const newButtonTwo={
      id: 'button'+nodeId+'b',
      draggable: true,
      position:id==2?{ x: 726, y: 673 }:{x:activeButtonVarient=='a'?nodeDetails?.position?.x-100:nodeDetails?.position?.x+(200),y:nodeDetails?.position?.y+300},
      tabType: 'Button',
      type: 'Button',
      data: { 
        ...values,
        position:id==2?{ x: 726, y: 673 }:{x:activeButtonVarient=='a'?nodeDetails?.position?.x-100:nodeDetails?.position?.x+(200),y:nodeDetails?.position?.y+300},
        parentId:`node-${id+1}`,
        id: 'button'+nodeId+'b',
        openAndCloseModal:"()=>openAndCloseModal()",
        setNodeDetails:"(arg)=>setNodeDetails(arg)",
        setActiveTab:"(arg)=>setActiveTab(arg)",
      },
      style: {
        border: '0',
        padding: '0',
        width: 'fit-content',
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
        zIndex: '10'
      }
  }

    if(id==1){

    setNodes((nds: any) => [...nds, newNode,newButton]);
    }else{
      if(nodeTypeArg=='Condition'){
        setNodes((nds:any)=>[
          ...nds.filter((node:any)=>{
            if(node?.id!==nodeDetails?.id){
              return node
            }
          }).map((value:any)=>{
            if(value?.id=='end'){
              return{
                ...value,
                position:id==1?{...value?.position}:id==2?{x:474,y:1042}:{x:474,y:value?.position?.y+300}
              }
            }else{
              return{
                ...value
              }
            }
          }),newNode,newButton,newButtonTwo
        ])
      }else  if(nodeTypeArg=='Action'){
        setNodes((nds:any)=>[
          ...nds.filter((node:any)=>{
            if(node?.id!==nodeDetails?.id){
              return node
            }
          }).map((value:any)=>{
            if(value?.id=='end'){
              return{
                ...value,
                position:id==1?{...value?.position}:id==2?{x:474,y:1042}:{x:474,y:value?.position?.y+300}
              }
            }else{
              return{
                ...value
              }
            }
          }),newNode,newButton
        ])
      }
    }

    if(id==1){

    setEdges((eds: any) => [
      ...eds.filter((value:any)=>{
        if(value?.source!=='1'&&value?.target!=='end'){
          return value
        }
      }),
      { id: `e1-${nodeId}`, source: '1', target: nodeId },
      { id: `e${nodeId}-button${nodeId}+a`, source: nodeId, target:'button'+nodeId+'a' },
      {id:`button${nodeId}a-end`,source:'button'+nodeId+'a',target:'end'}
    ]);
  }else{

    if(nodeTypeArg=='Condition'){
      setEdges((eds: any) => [
        ...eds.filter((value:any)=>{
          if(value?.source!==nodeDetails?.parentId||value?.target!==nodeDetails?.id){
            return value
          }
        }),
        { id: `e${nodeDetails?.parentId}-${nodeId}`, source:nodeDetails?.parentId, target: nodeId },
        { id: `e${nodeId}-button${nodeId}a`, source: nodeId, target:'button'+nodeId+'a' },
        { id: `e${nodeId}-button${nodeId}b`, source: nodeId, target:'button'+nodeId+'b' },
        {id:`button${nodeId}a-end`,source:'button'+nodeId+'a',target:'end'},
        {id:`button${nodeId}b-end`,source:'button'+nodeId+'b',target:'end'}
      ]);
    }else  if(nodeTypeArg=='Action'){
      setEdges((eds: any) => [
        ...eds.filter((value:any)=>{
          if(value?.source!==nodeDetails?.parentId||value?.target!==nodeDetails?.id){
            return value
          }
        }),
        { id: `e${nodeDetails?.parentId}-${nodeId}`, source:nodeDetails?.parentId, target: nodeId },
        { id: `e${nodeId}-button${nodeId}a`, source: nodeId, target:'button'+nodeId+'a' },
        {id:`button${nodeId}a-end`,source:'button'+nodeId+'a',target:'end'},
      ]);
    }

  }

    setId(id + 1); // Update the ID state
    setTempValues({})
  };

  useImperativeHandle(ref,()=>({
      addNewNode   
  }))

  const updateNode=()=>{
   let updatedNodes=nodes&&nodes?.map((value)=>{
      if(value?.id==nodeDetails?.id){ 
        return{...value,data:{...value?.data,values:{[nodeDetails?.type]:nodeDetails?.type=='condition'?[...tempValuesRef.current?.[nodeDetails?.type]]:tempValuesRef?.current?.[nodeDetails?.type]}}}
      }else{
        return value
      }
    })

    setEdges((eds:any)=>[...eds])

    enableEditModeForNodes()
    setNodes(updatedNodes)
    setTempValues({})
  }

  const deleteNode = useCallback((nodeId: any) => {
    setNodes((nds) => {
      // Recursive function to collect all child nodes to be deleted
      const collectChildNodes = (parentId: any, edges: any[]) => {
        let nodesToDelete = [parentId];
        let stack = [parentId];
  
        while (stack.length) {
          let currentId = stack.pop();
          edges.forEach(edge => {
            if (edge.source === currentId && edge.target !== "end") {
              nodesToDelete.push(edge.target);
              stack.push(edge.target);
            }
          });
        }
  
        return nodesToDelete;
      };
  
      // Get all nodes and edges
      let edges: any[] = [];
      setEdges((eds) => {
        edges = eds;
        return eds;
      });
  
      // Get all child nodes to be deleted, excluding "end" node
      const nodesToDelete = collectChildNodes(nodeId, edges);
  
      // Filter out the nodes to be deleted
      let updatedNodes = nds.filter(node => !nodesToDelete.includes(node.id));
  
      // Update edges to remove connections to/from deleted nodes
      let updatedEdges = edges.filter(edge => !nodesToDelete.includes(edge.source) && !nodesToDelete.includes(edge.target));
  
      // Find the parent node of the deleted node
      const parentNodeId = edges.find(edge => edge.target === nodeId)?.source;
  
      // Ensure parent node is connected to the "end" node only if all child nodes are deleted
      const remainingChildNodes = updatedNodes.filter(node => {
        return edges.some(edge => edge.source === parentNodeId && edge.target === node.id);
      });
  
      if (remainingChildNodes.length <= 0) {
        if (parentNodeId?.includes('a')) {
          updatedEdges.push({
            id: `e${parentNodeId}-end`,
            source: parentNodeId,
            target: "end",
          });
        }
      }
  
      // Insert a button node in place of the deleted node's position
      const deletedNode = nds.find(node => node.id === nodeId);
      if (deletedNode) {
        const newButtonNodeId = `button-replace-${nodeId}`;
        const newButtonNode = {
          id: newButtonNodeId,
          draggable: true,
          position: deletedNode.position, // Use the deleted node's position
          tabType: 'Button',
          type: 'Button',
          data: { 
            ...values,
            position: deletedNode.position,
            parentId: deletedNode.parentId,
            id: newButtonNodeId,
            openAndCloseModal: "()=>openAndCloseModal()",
            setNodeDetails: "(arg)=>setNodeDetails(arg)",
            setActiveTab: "(arg)=>setActiveTab(arg)"
          },
          style: {
            border: '0',
            padding: '0',
            width: 'fit-content',
            outline: 'none',
            boxShadow: 'none',
            background: 'transparent',
            zIndex: '10'
          }
        };
        updatedNodes.push(newButtonNode);
  
        // Reconnect edges: connect the parent to the new button node
        if (parentNodeId) {
          updatedEdges.push({
            id: `e${parentNodeId}-${newButtonNodeId}`,
            source: parentNodeId,
            target: newButtonNodeId
          });
        }
  
        // Connect the new button node to the "end" node
        updatedEdges.push({
          id: `e${newButtonNodeId}-end`,
          source: newButtonNodeId,
          target: "end"
        });
      }
  
      // Check if only nodes with IDs "1" and "end" remain, and inject default node if necessary
      if (updatedNodes.length === 2 && updatedNodes.some(node => node.id === "1") && updatedNodes.some(node => node.id === "end")) {
        const updatedDefaultNodes = initialNodes;
  
        updatedNodes = [...updatedDefaultNodes];
        setId(1);
        updatedEdges = initialEdges;
      }
  
      setEdges(updatedEdges);
      return updatedNodes;
    });
  }, [setNodes, setEdges]);
  

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (connection: any) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  // Create a function from the string
const createFunctionFromString = (funcString:any) => {
  try {
    return new Function(
      'addNewNode', 
      'openAndCloseModal', 
      'setNodeDetails',
      'setTempValues',
      'setActiveTab',
      'enableEditModeForNodes',
      'switchSteps',
      'deleteNode',
      'return ' + funcString
    )(addNewNode,openAndCloseModal,setNodeDetails,setTempValues,setActiveTab,enableEditModeForNodes,switchSteps,deleteNode);
  } catch (error) {
    console.error('Error creating function from string:', error,funcString);
    return null;
  }
};

const funcArr = ['addNewNode','openAndCloseModal','setNodeDetails','setTempValues','setActiveTab','enableEditModeForNodes','switchSteps','deleteNode'];

useEffect(() => {
  setNodes((nds) => {
    return nds.map((node) => {
      let updatedData = { ...node.data };
      Object.keys(updatedData).forEach((key) => {
        if (funcArr.includes(key)) {
          updatedData[key] = createFunctionFromString(updatedData[key]);
        }
      });
      return { ...node, data: updatedData };
    });
  });
}, [edges]);


useEffect(()=>{
  setValues({...values,sequence:{...values?.sequence,nodes:[...nodes],edges:[...edges]}})
},[edges])

console.log('Nodes',values,tempValuesRef?.current?.action)

  return (
    <div style={{ height: '100%' }} >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onInit={(instance: any) => {
          reactWire.current = instance;
          instance.fitView(); // Fit view when initialized
        }}
      >
        <Controls />
        <Background gap={12} size={1} />
      </ReactFlow>
      {isModalOpen&&<Modal>
        <ReactWireModals updateNode={updateNode} isEditEnabled={isEditEnabled} enableEditModeForNodes={enableEditModeForNodes} activeTab={activeTab} addNewNode={addNewNode} apiData={apiData} values={tempValues} setValues={setTempValues} openAndCloseModal={openAndCloseModal}/>
      </Modal>}
    </div>
  );
});

export default ReactWire;
