import styled from "styled-components";

export const DailyParent=styled.div`
border:1px solid #D0D5DD;
padding:20px;
min-width:600px;
min-height:200px;
border-radius:10px;
background: linear-gradient(105.46deg, #FFFFFF 1.24%, #F1F1F1 99.31%);

.daily-card-title{
font-size:18px;
font-weight:600;
color:#1D42BC;
letter-spacing:0.3px;
}

.daily-card-Repeat-Every-container{
margin-top:20px;
display:flex;
align-items:center;
flex-direction:row;
gap:20px;
}

.daily-card-Repeat-Every-txt{
font-size:16px;
font-weight:500;
color:#667085;
}

.daily-card-Repeat-Every-input-field{
width:80px;
height:45px;
border:1px solid #D0D5DD;
outline:none;
border-radius:10px; 
padding:0 10px;
}

.daily-card-Repeat-Every-final-txt{
    font-size:14px;
    font-weight:400;
    color:#000000;
    margin-top:20px;
    margin-left:120px;
    letter-spacing:0.4px;
}

.recurrence-button-container{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:end;
    flex-direction:row;
    gap:30px;
    margin-top:20px;
}

.recurrence-cancel-button{
  color:#2E59E7;
  font-size:16px;
  font-weight:500;
   padding:10px 60px;
}

.recurrence-save-button{
  background-color:#2E59E7;
  color:#FFFFFF;
  font-size:16px;
  font-weight:500;
  padding:10px 60px;
  border-radius:10px;
}
`