import React from 'react'
import {ReactFlowComp} from '@decisionm/react-gen-lib'

interface ReactSequenceFlowProps{
data?:any;
}

const ReactSequenceFlow:React.FC<ReactSequenceFlowProps> = (props) => {
  let nodes:any=`[
  {
    "id": "1",
    "draggable": true,
    "position": {
      "x": 0,
      "y": 0
    },
    "data": {
      "label": "Sequence Name"
    },
    "style": {
      "background": "#101828",
      "color": "#fff",
      "padding": "5px 10px",
      "fontSize": "16px",
      "borderRadius":"50px"
    }
  },
  {
    "id": "button",
    "type": "customButton",
    "draggable": true,
    "position": {
      "x": 60,
      "y": 250
    },
    "data": {
      "label": "wow",
      "buttonId":"button",
      "isAddFormOpenAndClose":"(id)=>isAddFormOpenAndClose(id)"
    },
    "style": {
      "border": "0",
      "padding": "0",
      "width": "fit-content",
      "outline": "none",
      "boxShadow": "none"
    }
  },
  {
    "id": "end",
    "draggable": true,
    "position": {
      "x": 5,
      "y": 600
    },
    "data": {
      "label": "End Automation"
    },
    "style": {
      "background": "#101828",
      "color": "#fff",
      "padding": "5px 10px",
      "fontSize": "16px",
      "borderRadius":"50px"
    }
  }
]
`
let edges:any=`[
  {
    "id": "e1-button",
    "source": "1",
    "target": "button"
  },
  {
    "id": "ebutton-end",
    "source": "button",
    "target": "end"
  }
]
`
    const{data={}}=props
    const {formOptions={},captureFlowJson=()=>{},initialNodes,initialEdges,defaultNodes}=data
    console.log('qqq-12',props)
 

  return (
    <ReactFlowComp formOptions={formOptions} captureFlowJson={captureFlowJson} initialNodes={initialNodes||nodes} initialEdges={initialEdges||edges} defaultNodes={defaultNodes}/>
  )
}

export default ReactSequenceFlow