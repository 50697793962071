import styled from "styled-components";

export const ActionParent=styled.div`
    display:flex;
    flex-direction:column;
    gap:50px;
    height:500px;
.action-tab-parent-container{
    height:40px;
    width:100%;
    border-radius:6px;
    background:#F6F6F6;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
}

.action-tab-section{
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:6px;
    font-size:16px;
    font-weight:500;
    letter-spacing:0.3px;
    cursor:pointer;
    user-select:none;
}

.in-active-action-tab-section{
    color:#475467;
}

.active-action-tab-section{
    border:3px solid #ABBEFF;
    background:#FFFFFF;
    color:#2E59E7;
    box-shadow: 0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A;

}

.action-form-parent-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:10px;
    height:calc(100% - 100px);
    overflow:auto;
}

.action-form-field-parent-container{
    width:100%;
    padding:0 10px;
    display:flex;
    flex-direction:column;
    gap:10px;
}

.action-form-input-field{
    width:100%;
    padding: 0 10px;
    outline:none;
    border-radius:6px;
    border:1px solid #cccccc;
    height:45px;
}

.action-form-input-field:focus{
    border:1px solid #ABBEFF;
}
 
.action-button-container{
    height:50px;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:30px;
    background:#FFFFFF;
}

.action-cancel-button{
    width:100%;
    height:100%;
    font-size:18px;
    font-weight:500;
    color:#2E59E7;
    border:none;
    background:#FFFFFF;
}

.action-save-button{
    width:100%;
    height:100%;
    font-size:18px;
    font-weight:500;
    background:#2E59E7;
    border:none;
    color:#FFFFFF;
    border-radius:5px;
}
`