import React,{useEffect, useState} from 'react'
import {DataGrid} from '@decisionm/react-gen-lib'

interface TableProps{
column?:any
data?:any
downloadName?:any
config?:any
filter?:any
otherServices?:any
getData?:()=>void|any
dataLoading?:any
}

const GridTable:React.FC<TableProps> = (props) => {
  const {dataLoading,column,data,config,otherServices,getData=()=>{return []}}=props

  const [tableData, setTableData] = useState(data);
  const [isLoading,setIsLoading]=useState(true)
  console.log('qqq-9',props)
  console.log('qqq-12',getData())
  console.log('qqq-25',isLoading)


  // const a=(value:any,column:any,index:any)=>{
  //   console.log('qqq-5',value,column,index)
  //   alert('guna')
  // }

  // let codeStr=` function DynamicComponent(props) {
    
  //     return <h1 >{props?.value?.name?.toString()}</h1>;
  //   }
  //   ReactDOM.render(<DynamicComponent {...componentProps} />, mountNode);`

  let tableColumn =column?.map((value:any)=>{
   return{...value}
  })
  
     let tableConfig={...config,itemsSizeOptions:[10,25,50,75,100]}||{
      pagination:true,
      rowsPerPage:true,
      rowsCount:true,
      dataLoadingStatus:false,
      goTo:false,
      pagesCount:false,
      itemsPerPage:8,
      itemsSizeOptions:[],
      dragable:false,
      download:{
        type:['csv','pdf'],
        name:'Report'
        }
    }

    useEffect(() => {
      const updateData = () => {
        setTableData(getData());
      };
      
      // Assume `externalUpdate` is an event or function triggered by AngularJS
      window.addEventListener('externalUpdate', updateData);
  
      return () => {
        window.removeEventListener('externalUpdate', updateData);
      };
    }, [getData]);

    useEffect(()=>{
      if(dataLoading?.status){
        setIsLoading(dataLoading?.status)
      }else{
      setTimeout(() => {
        setIsLoading(dataLoading?.status)
      }, 500);
    }
    },[dataLoading?.status])

  return (
    
    <DataGrid
    data={tableData}
    column={tableColumn}
    dataLoadingStatus={isLoading}
   {...tableConfig}
   advanceConfiguration={otherServices}
    />
  )
}

export default GridTable