import React from 'react'
import {StepsParent} from '../style/StepsStyle'
import {Stepper} from '@decisionm/react-gen-lib'

interface StepsProps{
    options?:any
    activeStep?:any
    switchSteps?:(arg:any)=>any
}

const Steps:React.FC<StepsProps> = (props) => {
    const{options,activeStep,switchSteps}=props
  return (
    <StepsParent>
        <Stepper options={options} activeStep={activeStep} onClick={(e:any)=>switchSteps?switchSteps(e):''}/>
    </StepsParent>
  )
}

export default Steps