import React, { useEffect } from 'react'
import {ExecuteOnParent} from '../style/ExecuteOnStyle'
import Select from './Select'
import {ClockIcon,RightArrowIcon,RepeatIcon} from '../../../utills/Svg'
import RecurrenceModals from './RecurrenceModals'

interface ExecuteOnProps{
    onClick?:(arg:any)=>void
    activeTab?:any
    modalOpen?:boolean
    values?:any
    setValues?:any
    openAndCloseModal:()=>void
}

const ExecuteOn:React.FC<ExecuteOnProps> = (props) => {
    const{onClick,activeTab,modalOpen,values,setValues,openAndCloseModal}=props
    const formName='executeOn'

    const options=[
        {label:'Does not repeat',value:'Does not repeat'},
        {label:'Hourly',value:'Hourly'},
        {label:'Daily',value:'Daily'},
        {label:'Weekly',value:'Weekly'},
        {label:'Monthly',value:'Monthly'},
        {label:'Yearly',value:'Yearly'},
    ]

    useEffect(() => {
      // if (values?.executeOn?.time?.frequency === '') {
      //   const { hours, days, months, weeks, years, ...updatedValues } = values?.executeOn?.time || {};
      //   setValues({
      //     ...values,
      //     executeOn: {
      //       ...values?.executeOn,
      //       time: { ...updatedValues, frequency: values?.executeOn?.time?.frequency },
      //     },
      //   });
      // }
    
      const updatedTimeValues = { ...values?.executeOn?.time };
      switch (values?.executeOn?.time?.frequency) {
        case 'Hourly':
          delete updatedTimeValues.days;
          delete updatedTimeValues.months;
          delete updatedTimeValues.weeks;
          delete updatedTimeValues.years;
          break;
        case 'Daily':
          delete updatedTimeValues.hours;
          delete updatedTimeValues.months;
          delete updatedTimeValues.weeks;
          delete updatedTimeValues.years;
          break;
        case 'Weekly':
          delete updatedTimeValues.hours;
          delete updatedTimeValues.days;
          delete updatedTimeValues.months;
          delete updatedTimeValues.years;
          break;
        case 'Monthly':
          delete updatedTimeValues.hours;
          delete updatedTimeValues.days;
          delete updatedTimeValues.weeks;
          delete updatedTimeValues.years;
          break;
        case 'Yearly':
          delete updatedTimeValues.hours;
          delete updatedTimeValues.days;
          delete updatedTimeValues.months;
          delete updatedTimeValues.weeks;
          break;
          case 'Does not repeat':
            delete updatedTimeValues.hours;
            delete updatedTimeValues.days;
            delete updatedTimeValues.months;
            delete updatedTimeValues.weeks;
            delete updatedTimeValues.years;
            break;
        default:
          break;
      }
    
      // Only update the state if the values have changed
      setValues({
        ...values,
        executeOn: {
          ...values?.executeOn,
          time: { ...updatedTimeValues },
        },
      });
    }, [values?.executeOn?.time?.frequency]);

    const captureSelectedRecurrence=(e:any)=>{
      handleChange(e,'time',false)
      openAndCloseModal()
    }

    const handleChange=(e:any,cat:any,varient:any)=>{
      const {name,value}=e?.target

      if(!cat&&!varient){
      setValues({...values,[formName]:{...values?.[formName],[name]:value}})
      }else if(cat&&varient){
        setValues({...values,
          [formName]:{
              ...values?.[formName],
              [cat]:{ 
                  ...values?.[formName]?.[cat],
                  [varient]:{
                    ...values?.[formName]?.[cat]?.[varient],[name]:value
                  }
                }
              }
            }
          )
      }else if(cat&&!varient){
        setValues({...values,
          [formName]:{
            ...values?.[formName],
            [cat]:{
              ...values?.[formName]?.[cat],
              [name]:value,
            }
          }})
      }
    }

  return (
    <ExecuteOnParent>
         <p className='execute-on-title'>Execute On : <span className='execute-on-sequence-name'>{values?.basicInfo?.sequenceName}</span> </p>

         <div className='execute-on-tab-parent-container'>
           <p role='button' onClick={()=>{if(onClick){onClick('time')}}} className={`execute-on-tab ${activeTab=='time'?'execute-on-tab-active':''}`}>Time</p>
           <p role='button' onClick={()=>{if(onClick){onClick('updatedField')}}} className={`execute-on-tab ${activeTab=='updatedField'?'execute-on-tab-active':''}`}>Update Field</p>
         </div>

         <div className='execute-on-time-tab-parent-container'>
                <div className='execute-on-time-and-date-parent'>
                    <ClockIcon size={30} color='#3E67F1'/>
                    <input className='execute-on-date-field' type='date' value={values?.[formName]?.time?.start?.date} name='date' onChange={(e:any)=>{handleChange(e,'time','start')}}/>
                    <input className='execute-on-time-field' type='time' value={values?.[formName]?.time?.start?.time} name='time' onChange={(e:any)=>{handleChange(e,'time','start')}}/>
                    <RightArrowIcon width={20} height={15} color={'#000000'}/>
                    <input className='execute-on-date-field' type='date' name='date' value={values?.[formName]?.time?.end?.date} onChange={(e:any)=>{handleChange(e,'time','end')}}/>
                    <input className='execute-on-time-field' type='time' name='time' value={values?.[formName]?.time?.end?.time} onChange={(e:any)=>{handleChange(e,'time','end')}}/>
                </div>
                <div className='execute-on-time-and-date-parent'>   
                    <RepeatIcon size={30} color='#3E67F1'/>
                    <Select options={options} name='frequency' onChange={(e)=>{captureSelectedRecurrence(e)}} value={values?.[formName]?.time?.frequency}/>
                </div>
         </div>
         <div className='execute-on-run-button-container'>
            <input id="executeOnRun" defaultChecked={values?.[formName]?.run?true:false} type='checkbox' className='execute-on-run-checkbox' name="run" onChange={(e:any)=>handleChange(e,false,false)}/>
            <label htmlFor='executeOnRun' className='execute-on-run-label'>Run</label>
         </div>
         {modalOpen&&<RecurrenceModals values={values} setValues={setValues} openAndCloseModal={openAndCloseModal}/>}
    </ExecuteOnParent>
  )
}

export default ExecuteOn