import styled from "styled-components";

export const ConditionParent=styled.div`
    display:flex;
    flex-direction:column;
    gap:20px;
 
.condition-parent-container{
    width:100%;
    height:250px;
    overflow:auto;
    display:flex;
    flex-direction:column;
    gap:20px;
}

.condition-inner-parent-container{
    display:flex;
    flex-direction:row;
    gap:15px;
    align-items:center;
    padding: 0 20px 0 0;
}

.condition-input-field{
    border:1px solid #CCCCCC;
    height:45px;
    border-radius:5px;
    width:100%;
    padding:0 10px;
    outline:none;
}

.condition-serial-no-container{
    height:45px;
    display:flex;
    align-items:center;
    width:100px;
   border-radius:5px;
   cursor:pointer;
   user-select:none;
   justify-content:center;  
}

.condition-serial-no{
    color:#667085;
    font-size:18px;
    font-weight:500;
    text-align:center;
}

.condition-serial-no-container:hover{
    background:rgba(239, 68, 68, 0.3);

    .condition-serial-no{
        display:none;
    }

    .close-condition-icon{
        display:block;
    }
}

.close-condition-icon{
    display:none;
    color:red;
    font-size:22px;
    font-weight:400;
}

.condition-gate-parent{
    width:150px;
    display:flex;
    align-items:center;
    justify-content:center;
}

.condition-gate-parent-container{
    display:flex;
    align-items:center;
    flex-direction:column;
    gap:5px;
    cursor:pointer;
    user-select:none;
}

.condition-gate-text{
    font-size:16px;
    font-weight:500;
    letter-spacing:0.2px;
    color:#2E59E7;
}

.condition-formula-parent-container{
    display:flex;
    flex-direction:column;
    gap:10px;
}

.condition-formula-title{
    font-size:14px;
    font-weight:700;
    letter-spacing:0.3px;
    color:#98A2B3;
}

.condition-formula-text{
    height:70px;
    font-size:18px;
    font-weight:500;
    letter-spacing:0.5px;
    color:#1D42BC;
    overflow:auto;
}

.condition-button-container{
    height:35px;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:30px;
    background:#FFFFFF;
    margin-top:0px;
}

.condition-cancel-button{
    width:100%;
    height:100%;
    font-size:18px;
    font-weight:500;
    color:#2E59E7;
    border:none;
    background:#FFFFFF;
}

.condition-save-button{
    width:100%;
    height:100%;
    font-size:18px;
    font-weight:500;
    background:#2E59E7;
    border:none;
    color:#FFFFFF;
    border-radius:5px;
}
`