import React,{useState} from 'react'
import {ActionParent} from '../../../style/ActionStyle'
import Select from '../../Select'
import MultiSelect from '../../../../commonComponents/MultiSelect'

interface ActionProps{
  openAndCloseModal?:()=>void
  addNewNode?:(arg:any)=>void
  values?:any
setValues?:any
isEditEnabled?:any
enableEditModeForNodes?:any
updateNode?:any
}

const Action:React.FC<ActionProps> = (props) => {
  const{openAndCloseModal,addNewNode,values,setValues,isEditEnabled,updateNode}=props

  const [selectedTab,setSelectedTab]=useState('email')
  const [actionData,setActionData]=useState<any>({['email']:values?.action?values?.action:{}})

  const emailOptions=[
    {label:'mohideenrisviy@decisionminds.com',value:'mohideenrisviy@decisionminds.com'},
    {label:'gunasekaranb@decisionminds.com',value:'gunasekaranb@decisionminds.com'}
  ]

  const emailTemp=[
    {label:'Welcome',value:'welcome'},
    {label:'Credit Card Follow Up',value:'creditCardFollowUp'},
    {label:'Final mail',value:'finalMail'}
  ]

  const tabOption=[
    {label:'Email',value:'email'},
    {label:'EPA',value:'epa'},
    {label:'Assign Task',value:'assignTask'},
    {label:'Update Field',value:'updateField'},
    {label:'None',value:'none'}
  ]

  const selectTab=(tab:any)=>{
    setSelectedTab(tab)
  }

  const cancel=(arg:boolean)=>{
    if(openAndCloseModal){
        openAndCloseModal()
        if(arg){
        setValues({})
        }
    }
  }

  const save=()=>{
    setTimeout(() => {
      if(addNewNode && openAndCloseModal){
        addNewNode('Action')
        openAndCloseModal()
      }
    }, 100);

    setValues({...values,action:actionData?.[selectedTab]})
  }

  const handleChange=(e:any)=>{
    const{name,value}=e?.target

    setActionData({...actionData,[selectedTab]:{...actionData?.[selectedTab],[name]:value}})
  }

  const updateAction=()=>{
    setValues({action:actionData?.[selectedTab]})
    setTimeout(() => {
      updateNode()
        cancel(false)
    }, 300);
  }

  console.log('Action Node',actionData,values)

  return (
   <ActionParent>
      <div className='action-tab-parent-container'>
      {tabOption&&tabOption?.map((value,key)=>(<p onClick={()=>selectTab(value?.value)} key={key} className={`action-tab-section ${selectedTab==value?.value?'active-action-tab-section':'in-active-action-tab-section'}`}>{value?.label}</p>))}
      </div>
      <div className='action-form-parent-container'>
        <div className='action-form-field-parent-container'>
            <label>From</label>
            <input className='action-form-input-field' name='from' value={actionData?.email?.from} onChange={(e:any)=>handleChange(e)}/>
        </div>
        <div className='action-form-field-parent-container'>
            <label>To</label>
            <MultiSelect options={emailOptions} value={actionData?.email?.to} name={'to'} onChange={(e:any)=>handleChange(e)}/>
        </div>
        <div className='action-form-field-parent-container'>
            <label>CC</label>
            <MultiSelect options={emailOptions} value={actionData?.email?.cc} name={'cc'} onChange={(e:any)=>handleChange(e)}/>
        </div>
        <div className='action-form-field-parent-container'>
            <label>Email Template</label>
          <Select options={emailTemp} name='emailTemplate' value={actionData?.email?.emailTemplate} onChange={(e:any)=>handleChange(e)}/>
        </div>
      </div>
      <div className='action-button-container'>
              <button onClick={()=>cancel(true)} className='action-cancel-button'>Cancel</button>
              {!isEditEnabled&&<button onClick={save} className='action-save-button'>Save</button>}
              {isEditEnabled&&<button onClick={updateAction} className='action-save-button'>Update</button>}
      </div>
   </ActionParent>
  )
}

export default Action