import React, { useEffect, useRef, useState } from 'react'
import {ConditionNodeParent} from '../../style/ConditionNodeStyles'
import {RightPointArrowIcon,BranchIcon} from '../../../../utills/Svg'
import { Handle, Position } from '@xyflow/react';
import {generateExpression} from '../../../../utills/CommonLogic'

interface ConditionNodeProps{
    data:any;
    isConnectable?:any;
}

const ConditionNode:React.FC<ConditionNodeProps> = (props) => {

    const {isConnectable,data}=props

    const {values,openAndCloseModal,setTempValues,setNodeDetails,id,enableEditModeForNodes,setActiveTab,deleteNode}=data

    const [openButton,setOpenButton]=useState<{}|any>({})

    const conditionNodeButtonRef=useRef<HTMLDivElement>(null)

    const formula=generateExpression(values?.condition)

    const openEditModal=(arg:any)=>{
        setActiveTab('condition')
        setTempValues({condition:arg?.values?.condition})
        setNodeDetails({id:id,type:'condition'})
        enableEditModeForNodes()
       setTimeout(() => {
        openAndCloseModal()
       }, 100);
    }

    const openAndCloseButton=()=>{
        setOpenButton({[id]:openButton?.[id]?false:true})
      }
  
      useEffect(() => {
        // Function to close the filter when clicked outside
        function handleClickOutside(event: MouseEvent): void {
          if (conditionNodeButtonRef.current && !conditionNodeButtonRef.current.contains(event.target as Node)) {
           setOpenButton({})
          }
        }
    
        // Add event listener when the filter is open
        if (openButton) {
          document.addEventListener('mousedown', handleClickOutside);
        }
    
        // Clean up event listener when component unmounts or filter closes
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [openButton]);

      const deleteNodes=()=>{
        deleteNode(id)
      }
  

    console.log('condition Node',data)
  return (
   <ConditionNodeParent>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className='condition-node-overall-parent-container'>
        <div className='condition-node-tag-icon-container'>
                <BranchIcon width={15} height={12} color='#0948C3'/>
            </div> 
            <div className='condition-node-parent-container'>
                <div role='button' onClick={openAndCloseButton} className='condition-node-title-container'>
                    <p>Condition</p>
                    <RightPointArrowIcon width={10} height={30} color='#0948C3'/>
                </div>
                <div  className='condition-node-body-conteiner'>
                    <div className='condition-node-body-inner-container'>
                        <p className='condition-node-body-right-text'>{formula}</p>
                    </div>
                </div>
            </div>
            <Handle
            type="source"
            position={Position.Bottom}
            id="b"
            isConnectable={isConnectable}
        />
      </div>
      {openButton?.[id]&&<div className='action-buttons-parent-container'>
        <button className='action-edit-button' onClick={()=>openEditModal(data)} >Edit</button>
        <button className='action-delete-button' onClick={deleteNodes}>Delete</button>
      </div>}
   </ConditionNodeParent>
  )
}

export default ConditionNode