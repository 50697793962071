import React from 'react'
import {BasicInfoParent} from '../style/BasicInfoStyle'

interface BasicInfoProps{
  values?:any
  setValues?:any
}

const BasicInfo:React.FC<BasicInfoProps> = (props) => {
  const {values,setValues}=props
  const formName='basicInfo'

  const handleChange=(e:any)=>{
    const {name,value}=e?.target
    setValues({...values,[formName]:{...values?.[formName],[name]:value}})
  }

  return (
    <BasicInfoParent>
      <p className='basic-info-title'>Basic Info :</p>

      <div className='basic-info-form-parent-container'>
          <div className='basic-info-form-inner-container'>
            <label className='basic-info-form-label field-is-required'>Sequence Name</label>
            <input className='basic-info-form-input-field' value={values?.[formName]?.sequenceName} name='sequenceName' onChange={(e:any)=>handleChange(e)}/>
          </div>
          <div className='basic-info-form-inner-container'>
            <label className='basic-info-form-label'>Description</label>
            <input className='basic-info-form-input-field' value={values?.[formName]?.description} name="description" onChange={(e:any)=>handleChange(e)}/>
          </div>
      </div>
    </BasicInfoParent>
  )
}

export default BasicInfo