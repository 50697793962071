import React,{useEffect, useRef, useState} from 'react'
import {SelectParent} from '../style/SelectStyle'
import {DownArrowMarkIcon} from '../../../utills/Svg'

interface SelectProps{
    options?:any;
    name?:string;
    onChange?:(arg:any)=>void
    value?:any
    disabled?:boolean
    index?:any
    returnData?:boolean
}

const Select:React.FC<SelectProps> = (props) => {
    const {options,name,onChange,value,disabled=false,index,returnData}=props

    const [selectedValue,setSelectedValue]=useState<string|number|any>(value)
    const [selectedLabel,setSelectedLabel]=useState('')
    const [optionStatus,setOptionStatus]=useState<boolean>(false)

    useEffect(()=>{
      setSelectedValue(value)

      let selectedOptions=options&&options?.filter((opt:any)=>{
        if(opt?.value==value){
          return opt
        }
      })
      setSelectedLabel(selectedOptions?.at(0)?.label)
    },[index,value])

    const selectRef = useRef<HTMLDivElement>(null);

    const openAndCloseOption=()=>{
        setOptionStatus(!optionStatus)
    }

    const captureValueFromSelect=(arg:any)=>{
      const {value,label}=arg
        let obj={
            target:{
                value:value,
                name:name
            },
            data:returnData?arg:null
        }
        setSelectedLabel(label)
        setSelectedValue(value)
        if(onChange){
            onChange(obj)
        }

        openAndCloseOption()
    }

    useEffect(() => {
        // Function to close the filter when clicked outside
        function handleClickOutside(event: MouseEvent): void {
          if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setOptionStatus(false);
          }
        }
    
        // Add event listener when the filter is open
        if (optionStatus) {
          document.addEventListener('mousedown', handleClickOutside);
        }
    
        // Clean up event listener when component unmounts or filter closes
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [selectedValue,optionStatus]);

  return (
    <SelectParent key={index}>
        <div style={{background:disabled?'#F5F5F5':'#FFFFFF'}} className='react-flow-select-parent-container' onClick={()=>!disabled?openAndCloseOption():null} ref={selectRef}>
            <p className='react-flow-select-text'>{selectedLabel?.toString()?.length>0?selectedLabel:'Select'}</p>
            <DownArrowMarkIcon width={20} height={10} color='#667085'/>
        </div>
        {optionStatus&&<div className='react-flow-select-option-container' ref={selectRef}>
           {options&&options?.map((value:any,index:number)=>{
            return(
                <p key={index} className='react-flow-select-option' onClick={()=>captureValueFromSelect(value)}>{value?.label}</p>
            )
           })}
        </div>}
    </SelectParent>
  )
}

export default Select