import { useEffect } from 'react';
import './main/ComponentInjector.ts';
import { renderReactComponent } from './utills/CommonLogic';
// import { Charts } from './components';

function App(): JSX.Element {

  useEffect(() => {
    if (document.getElementById('renderWidget')) {
      renderReactComponent('renderWidget', {}, {})
    } else {
      alert("failure")
    }

  }, [])



  return (
    <div id="renderWidget">
      {/* <ComponentsRenderer pageId="test" payload={"siva"}/> */}
    </div>
  );
}

export default App;
